/* eslint-disable jsx-a11y/media-has-caption */
import { useState, React } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import data from '../../assets/data.json';

function video() {
  const [isPlay, setIsPlay] = useState(false);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const handlePlay = () => {
    setIsPlay(true);
  };
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={6} sm={12} className="flag_video">
            {!isPlay ? (
              <div
                onClick={() => {
                  handlePlay();
                }}
                style={{ cursor: 'pointer' }}
              >
                <img
                  src="/images/india_flag.png"
                  alt="play"
                  className="video"
                />
              </div>
            ) : (
              <>
                <video
                  className="video"
                  poster="/images/india_flag.jpg"
                  controls
                  autoPlay
                  preload="auto"
                >
                  <source src={data.language[0].video} type="video/mp4" />
                  {/* <track
                    src={data.language[0].video}
                    kind="captions"
                    srcLang="en"
                    label="english_captions"
                  /> */}
                </video>
              </>
            )}
          </Col>
          <Col lg={{ span: 5, offset: 1 }} sm={12} className="text-center">
            <div className="row">
              <h3 className="vid_title topic-title my-4 ">
                {t('video_title')}
              </h3>
              <p className="video_text topic-desc">{t('video_text')}</p>
            </div>

            {/* <img
              className="watch_more mt-5"
              src={data.language[0].watch_more}
              alt="watch more"
            /> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default video;
