import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import data from '../../assets/data.json';

function about() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div>
      <Container className="mt-3" fluid>
        <Row>
          <Col lg={6} sm={12} className="d-flex justify-content-center">
            <img
              className="flag_img img-fluid"
              src="/images/flag.png"
              alt="flag"
            />
          </Col>
          <Col lg={6} sm={12}>
            <h3 className="flag_title pt-lg-5 mt-3 mb-4 topic-title">
              {t('about_the_flag')}
            </h3>
            <p className="flag_text  topic-desc">{t('flag_text')}</p>
            <Link className="about_btn" to="/blog">
              <div className="flag_link">
                <div
                  className="row mt-5"
                  style={{
                    backgroundImage: `url(${'/images/org_btn.svg'})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    // backgroundColor: '#F97428',
                    height: 'auto',
                    width: 'max-content',
                    borderRadius: '10px',
                    padding: '3%',
                  }}
                >
                  <div className="col d-flex align-items-center justify-content-center">
                    <p className="btn_text my-2 ">{t('know_more')}</p>
                  </div>
                  {/* <div className="col-4 py-2">
                    <img src="/images/arrow_btn.svg" alt="" />
                  </div> */}
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default about;
