import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Container, Row, Col, NavDropdown, Dropdown } from 'react-bootstrap';

import { useTranslation, Trans } from 'react-i18next';
import { availableLocales, flags } from 'utils';
import { setUserLocale } from 'state/actions/preferences';
import { useFormatMessage } from 'hooks';
import { logout } from 'state/actions/auth';
import paths from 'pages/Router/paths';
import defaultLogo from 'assets/user-default-log.svg';
import Link from '../Link';

const NavBar = ({ handleMobileToggle, asideMobileActive }) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [navMobileActive, setNavMobileActive] = useState(false);

  const { userName, logoUrl, locale } = useSelector(
    (state) => ({
      userName: state.auth.userData.name,
      logoUrl: state.auth.userData.logoUrl,
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onClickLogoutHandler = () => {
    dispatch(logout());
  };

  const onMobileToggleHandler = useCallback(() => {
    setNavMobileActive(!navMobileActive);
  }, [setNavMobileActive, navMobileActive]);

  const changeLocaleHandler = (local) => {
    dispatch(setUserLocale(local));
  };

  const locales = availableLocales.filter((local) => local !== locale);
  let val;
  if (userName === undefined) {
    val = (
      <nav id="navbar-main" style={{zIndex:100}} className="navbar is-fixed-top">
        <div className="navbar-brand">
          <Link to="/">
            <img
              className="mr-lg-2 ml-lg-2 mx-md-3  mx-3 img-fluid"
              src="/images/logo3.svg"
              alt="logo"
              style={{ minHeight: '6vh' }}
            />
          </Link>
          <Link to="/">
            <img
              className="mr-lg-2 ml-lg-2 mx-md-3  mx-3 img-fluid"
              src="/images/logo1.svg"
              alt="logo"
            />
          </Link>
          {/* <a
      className="navbar-item is-hidden-desktop jb-aside-mobile-toggle"
      onClick={handleMobileToggle}
    >
      <span className="icon">
        <i
          className={classNames(
            'mdi',
            'mdi-24px',
            { 'mdi-forwardburger': !asideMobileActive },
            { 'mdi-backburger': asideMobileActive }
          )}
        />
      </span>
    </a> */}
        </div>

        <div className="navbar-brand is-right">
          <a
            className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
            data-target="navbar-menu"
            onClick={onMobileToggleHandler}
          >
            <span className="icon">
              <i
                className={classNames(
                  'mdi',
                  { 'mdi-dots-vertical': !navMobileActive },
                  { 'mdi-close': navMobileActive }
                )}
              />
            </span>
          </a>
        </div>
        <div
          className={classNames('navbar-menu', 'fadeIn', 'animated', 'faster', {
            'is-active': navMobileActive,
          })}
          id="navbar-menu"
        >
          <div className="navbar-end">
            <a className="navbar-item head-item" href="/">
              {t('home')}
            </a>
            <a className="navbar-item  head-item" href="/blog">
              {t('blog')}
            </a>

            {/*
                        <a className="navbar-item" href="/faq">
              {t('faq')}
            </a> <a className="navbar-item" href="/salient_feature">
              {t('salient_feature')}
            </a> */}
            <a className="navbar-item  head-item" href="/flag-suppliers">
              {t('flag_suppliers')}
            </a>
            <a className="navbar-item  head-item" href="/downloads">
              {t('downloads')}
            </a>
            <NavDropdown
              title={t('flag_code')}
              className="navbar-item"
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item href="/salient_feature">
                {t('salient_feature')}
              </NavDropdown.Item>
              <NavDropdown.Item href="/faq">{t('faq')}</NavDropdown.Item>
            </NavDropdown>
            <Col className="lang_div">
              <NavDropdown
                title={
                  <div className="langLogo float">
                    <img
                      className="my-float"
                      src="./images/lang.svg"
                      alt="Language"
                    />
                  </div>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item onClick={() => changeLanguage('en')}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('hin')}>
                  हिन्दी
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('mar')}>
                  मराठी
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('pun')}>
                  ਪੰਜਾਬੀ
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('as')}>
                  অসমীয়া
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('tel')}>
                  తెలుగు
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => changeLanguage('tam')}>
                  தமிழ்
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('kan')}>
                  ಕನ್ನಡ
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('ben')}>
                  বাংলা
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('mal')}>
                  മലയാളം
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('od')}>
                  ଓଡ଼ିଆ
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('guj')}>
                  ગુજરાતી
                </NavDropdown.Item>
              </NavDropdown>
            </Col>
            {/* <a
              className="navbar-item"
              target="_blank"
              rel="noopener noreferrer"
              href="https://shop.harghartiranga.com/"
            >
              {t('gift_flag')}
            </a> */}

            {/* <a className="navbar-item" href="/login">
              {t('login')}
            </a> */}
          </div>
        </div>
      </nav>
    );
  } else {
    val = (
      <nav id="navbar-main" className="navbar is-fixed-top">
        <div className="navbar-brand">
          <Link to="/">
            <img
              className="mr-lg-2 ml-lg-2 mx-md-3 mx-3"
              src="/images/logo3.svg"
              alt="logo"
              style={{ minHeight: '6vh' }}
            />
          </Link>
          <Link to="/">
            <img
              className="mr-lg-2 ml-lg-2 mx-md-3 mx-3"
              src="/images/logo1.svg"
              alt="logo"
            />
          </Link>
          {/* <a
      className="navbar-item is-hidden-desktop jb-aside-mobile-toggle"
      onClick={handleMobileToggle}
    >
      <span className="icon">
        <i
          className={classNames(
            'mdi',
            'mdi-24px',
            { 'mdi-forwardburger': !asideMobileActive },
            { 'mdi-backburger': asideMobileActive }
          )}
        />
      </span>
    </a> */}
        </div>
        <div className="navbar-brand is-right">
          <a
            className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
            data-target="navbar-menu"
            onClick={onMobileToggleHandler}
          >
            <span className="icon">
              <i
                className={classNames(
                  'mdi',
                  { 'mdi-dots-vertical': !navMobileActive },
                  { 'mdi-close': navMobileActive }
                )}
              />
            </span>
          </a>
        </div>
        <div
          className={classNames('navbar-menu', 'fadeIn', 'animated', 'faster', {
            'is-active': navMobileActive,
          })}
          id="navbar-menu"
        >
          <div className="navbar-end">
            {/* <a className="navbar-item">
              <img src="/images/lang.svg" alt="language" />
            </a> */}

            {/* ------------------LANGUAGE---------------------------- */}
            {/* <NavDropdown
              title={
                <div className="langLogo float">
                  <img
                    className="my-float img-fluid lang"
                    src="./images/lang.svg"
                    alt="Language"
                  />
                </div>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => changeLanguage('en')}>
                English
              </NavDropdown.Item>
            </NavDropdown> */}

            {/* ------------------LANGUAGE---------------------------- */}
            <a className="navbar-item  head-item" href="/">
              {t('home')}
            </a>
            <a className="navbar-item  head-item" href="/blog">
              {t('blog')}
            </a>
            {/* <a className="navbar-item" href="/faq">
              {t('faq')}
            </a>
            <a className="navbar-item" href="/salient_feature">
              {t('salient_feature')}
            </a> */}
            <a className="navbar-item" href="/flag-suppliers">
              {t('flag_suppliers')}
            </a>
            <a className="navbar-item  head-item" href="/downloads">
              {t('downloads')}
            </a>
            <NavDropdown
              title={t('flag_code')}
              className="navbar-item"
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item href="/salient_feature">
                {t('salient_feature')}
              </NavDropdown.Item>
              <NavDropdown.Item href="/faq">{t('faq')}</NavDropdown.Item>
            </NavDropdown>
            {/* <a
              className="navbar-item"
              target="_blank"
              rel="noopener noreferrer"
              href="https://shop.harghartiranga.com/"
            >
              {t('buy_flag')}
            </a>
            <a
              className="navbar-item"
              target="_blank"
              rel="noopener noreferrer"
              href="https://shop.harghartiranga.com/"
            >
              {t('gift_flag')}
            </a> */}
            <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
              {/* <a className="navbar-link is-arrowless">
          <div className="is-user-avatar">
            <span>
              <img id={locale} src={flags[locale]} alt={`${locale} flag`} />
            </span>
          </div>
          <span className="icon">
            <i className="mdi mdi-chevron-down" />
          </span>
        </a> */}
              {/* <div className="navbar-dropdown">
                {locales.map((local) => (
                  <a
                    onClick={() => changeLocaleHandler(local)}
                    className="navbar-item"
                    id={local}
                    key={local}
                  >
                    <div className="is-user-avatar">
                      <span>
                        <img src={flags[local]} alt={`${local} flag`} />
                      </span>
                    </div>
                  </a>
                ))}
              </div> */}
            </div>
            <Col className="lang_div">
              <NavDropdown
                title={
                  <div className="langLogo float">
                    <img
                      className="my-float"
                      src="./images/lang.svg"
                      alt="Language"
                    />
                  </div>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item onClick={() => changeLanguage('en')}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('hin')}>
                  हिन्दी
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('mar')}>
                  मराठी
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('pun')}>
                  ਪੰਜਾਬੀ
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('as')}>
                  অসমীয়া
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('tel')}>
                  తెలుగు
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => changeLanguage('tam')}>
                  தமிழ்
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('kan')}>
                  ಕನ್ನಡ
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('ben')}>
                  বাংলা
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('mal')}>
                  മലയാളം
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('od')}>
                  ଓଡ଼ିଆ
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => changeLanguage('guj')}>
                  ગુજરાતી
                </NavDropdown.Item>
              </NavDropdown>
            </Col>
            <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
              <a className="navbar-link is-arrowless">
                <div className="is-user-avatar">
                  <img src={logoUrl || defaultLogo} alt="User" />
                </div>
                <div className="is-user-name">
                  <span>{userName}</span>
                </div>
                <span className="icon">
                  <i className="mdi mdi-chevron-down" />
                </span>
              </a>
              <div className="navbar-dropdown">
                {/* <Link to={paths.PROFILE} onClick={onMobileToggleHandler}>
                  <span className="icon">
                    <i className="mdi mdi-account" />
                  </span>
                  <span>{useFormatMessage('NavBar.profile')}</span>
                </Link> */}
                <hr className="navbar-divider" />
                <a
                  className="navbar-item"
                  id="logout"
                  onClick={onClickLogoutHandler}
                >
                  <span className="icon">
                    <i className="mdi mdi-logout" />
                  </span>
                  <span>{useFormatMessage('NavBar.logOut')}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
  return val;
};

NavBar.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
  asideMobileActive: PropTypes.bool,
};

export default NavBar;
