/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-duplicates
import React from 'react';
import { useFilePicker } from 'use-file-picker';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { useTranslation, Trans } from 'react-i18next';
// eslint-disable-next-line import/no-duplicates
import { useEffect } from 'react';
import Layout from '../../components/Layout';

import firebase from '../../firebase';
import { firebaseError, uiConfig } from '../../utils';
import Footer from '../../components/Navigation/Footer';

function index() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [currentLang, setCurrentLang] = React.useState(i18n.language);
  React.useEffect(() => {
    setCurrentLang(i18n.language);
    console.log(i18n.language, 'LANGUGAE');
  }, [currentLang]);

  const [userImage, setUserImage] = React.useState(null);
  const [isGranted, setIsGranted] = React.useState(false);
  const [fileURL, setFileURL] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [locationStatus, setLocationStatus] = React.useState('Next');

  // const [locationStatus, setLocationStatus] = currentLang.startsWith('hin')
  //   ? React.useState('नेक्स्ट')
  //   : React.useState('Next');

  const [success, setSuccess] = React.useState(false);

  const [name, setName] = React.useState('');
  const [mobile, setMobile] = React.useState(null);

  const [fireUser, setfireUser] = React.useState(null);

  const [focused, setFocused] = React.useState(false);

  const ref = React.useRef(null);

  const mobileref = React.useRef(null);

  const [
    openFileSelector,
    { filesContent, plainFiles, loading, clear },
  ] = useFilePicker({
    accept: 'image/*',
    maxFileSize: 1024 * 5,
    readAs: 'DataURL',
  });

  React.useEffect(() => {
    // check current geo location
    const poshandle = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setIsGranted(true);
            if (currentLang.startsWith('en')) {
              setLocationStatus('Next');
            }
            if (currentLang.startsWith('hin')) {
              setLocationStatus('नेक्स्ट');
            }
            if (currentLang.startsWith('mar')) {
              setLocationStatus('पुढे');
            }
            if (currentLang.startsWith('pun')) {
              setLocationStatus('ਅਗਲਾ');
            }
            if (currentLang.startsWith('as')) {
              setLocationStatus('পৰৱৰ্তী');
            }
            if (currentLang.startsWith('tel')) {
              setLocationStatus('తరువాత');
            }
            if (currentLang.startsWith('ben')) {
              setLocationStatus('পরবর্তী');
            }
            if (currentLang.startsWith('tam')) {
              setLocationStatus('அடுத்தது');
            }
            if (currentLang.startsWith('guj')) {
              setLocationStatus('આગળનું');
            }
            if (currentLang.startsWith('kan')) {
              setLocationStatus('ಮುಂದೆ');
            }
            if (currentLang.startsWith('mal')) {
              setLocationStatus('അടുത്തത്');
            }
            if (currentLang.startsWith('od')) {
              setLocationStatus('ତା ପରେ');
            }

            // setLocationStatus('Next');
          },
          (error) => {
            // //console.log(error);
            setIsGranted(false);
            setLocationStatus('Location Permission Denied');
          }
        );
      } else {
        setIsGranted(false); // browser doesn't support geolocation
        // //console.log('Geolocation is not supported by this browser.');
        setLocationStatus('Browser is not supported');
      }
    };

    setInterval(poshandle, 1000);

    return () => clearInterval(poshandle);
  }, []);

  React.useEffect(() => {
    const footer = document.getElementById('footer');
    if (footer) {
      const style =
        ref.current === document.activeElement ||
        mobileref.current === document.activeElement
          ? (footer.hidden = true)
          : (footer.hidden = false);
      footer.style.display = style;
    }
  }, [ref.current, document.activeElement, mobileref.current]);

  React.useEffect(() => {
    if (plainFiles.length > 0) {
      // setFile(filesContent[0]);

      setUserImage(plainFiles[0]);
    }
  }, [plainFiles]);

  // upload image to firebase storage with progress
  React.useEffect(() => {
    if (userImage) {
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef
        .child(`images/${userImage.name}`)
        .put(userImage);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // progress function
          const progresss =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          setProgress(Math.round(progresss));
        },
        (error) => {},
        () => {
          // complete function
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            setFileURL(downloadURL);
            setSuccess(true);
            setProgress(0);
            setTimeout(() => {
              setSuccess(false);
            }, 2000);
          });
        }
      );
    }
  }, [userImage]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const usr = {
          name: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        };
        setName(usr.name);
        // setUserImage(usr.photoURL);

        localStorage.setItem('user', JSON.stringify(usr));
        setfireUser(user);
      }
    });
  }, []);

  useEffect(() => {
    if (fireUser) {
      setName(fireUser.displayName);
      setMobile(fireUser.phoneNumber);
      setFileURL(fireUser.photoURL);
    }
  }, [fireUser]);

  const onSignInSuccessHandler = (authResult) => {};

  const onSignInFailHandler = (signInEror) => {};

  const onSignOutHandler = () => {
    firebase.auth().signOut();
    setfireUser(null);
    setName(null);
    setMobile(null);
  };

  const handleSubmit = () => {
    if (name != null && name !== '' && isGranted) {
      const user = {
        name,
        mobile: mobile || '',
        image: fileURL || '',
      };

      localStorage.setItem('HGT_USER', JSON.stringify(user));
      window.location.href = '/landing';
    }
  };

  const handleLocationPermission = () => {
    setIsGranted(!isGranted);
    if (isGranted) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setIsGranted(true);
          },
          (error) => {
            setIsGranted(false);
          },
          { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        );
      }
    }
  };

  function getRandomFileName() {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    const random = `${Math.random()}`.substring(2, 8);
    const randomNumber = timestamp + random;
    return randomNumber;
  }

  return (
    <Layout>
      <div
        className="container mt-4 pb-3"
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${'./images/bg_img1.png'})`,
          // height: '90vh',
        }}
      >
        <div className="row d-flex align-items-center pt-5">
          <div className="col-lg-12 col-md-12">
            <div>
              {/* <p className="user_text text-center">
                <span className="user_bold">‘{t('video_title')}’</span>{' '}
                <span className="user_bold">{t('azadi')}.</span>
              </p> */}
              <p className="user_text text-center user_bold">
                {t('user_detail1')}
              </p>
              <br />
              <p className="user_text text-center user_bold">
                {t('user_detail2')}
              </p>
            </div>
            <br />
            <div className="row d-flex justify-content-center">
              <div
                className="col-12 py-3 px-4"
                style={{
                  width: 'auto',
                }}
              >
                <div
                  className="col-12 d-flex flex-column text-center "
                  onClick={() => openFileSelector()}
                >
                  <div>
                    <img
                      style={{ width: '64px', height: '64px' }}
                      className={`img-fluid cursor-pointer ${
                        fireUser == null
                          ? filesContent?.length > 0
                            ? 'rounded-circle'
                            : ''
                          : 'rounded-circle'
                      }`}
                      src={
                        fireUser == null
                          ? filesContent?.length > 0
                            ? filesContent[0].content
                            : './images/user_img.png'
                          : fireUser.photoURL
                      }
                      alt="pic"
                    />
                  </div>
                  <div className="d-flex justify-content-center pt-2">
                    <div className="user_text">{t('profile')}</div>
                  </div>
                </div>
                <div className="text-center">
                  {progress !== 0 && (
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${progress}%` }}
                      >
                        {progress}
                      </div>
                    </div>
                  )}
                  {plainFiles.length > 0 && (
                    <div>
                      {plainFiles[0].name}
                      <div
                        onClick={() => clear()}
                        className="mx-2 float-right close_btn"
                      >
                        X
                      </div>
                    </div>
                  )}
                  {success && (
                    <div className="alert alert-success mx-4" role="alert">
                      {t('upload_successfully')}
                    </div>
                  )}
                </div>
                <div
                  className="col-12  justify-content-center"
                  style={{ display: 'inline-grid' }}
                >
                  <div className="row pt-4 d-flex justify-content-center">
                    <div className="col-3 user_text_l">{t('name')}</div>
                    <div className="col-9">
                      <input
                        type="text"
                        maxLength={20}
                        className="input-box"
                        name="Name"
                        id="username"
                        ref={ref}
                        placeholder={t('enter_your_name')}
                        value={name}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row pt-4 d-flex justify-content-center">
                    <div className="col-3 user_text_l text-break">
                      {t('mobile')}
                    </div>
                    <div className="col-9">
                      <input
                        type="tel"
                        ref={mobileref}
                        className="input-box"
                        name="Phone number"
                        maxLength={10}
                        placeholder="8888888888"
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="my-3  d-flex justify-content-center">
                    <button
                      className="next_btn"
                      onClick={handleSubmit}
                      disabled={!isGranted || name?.length < 2}
                      style={{
                        color:
                          isGranted && name?.length > 2 ? 'green' : '#767575',
                        border:
                          isGranted && name?.length > 2
                            ? '2px solid white'
                            : '2px solid #b0aeae',
                        backgroundColor:
                          isGranted && name?.length > 2 ? 'white' : '#b0aeae',
                      }}
                    >
                      {locationStatus}
                    </button>
                  </div>

                  <div className="text-center">{t('or')}</div>
                  <div>
                    {fireUser == null ? (
                      <StyledFirebaseAuth
                        uiConfig={uiConfig(
                          onSignInSuccessHandler,
                          onSignInFailHandler
                        )}
                        firebaseAuth={firebase.auth()}
                      />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <div
                          className="btn btn-info mt-2 mx-6"
                          onClick={onSignOutHandler}
                        >
                          {t('logout')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="my-3">
                <p className="user_text user_italic text-center">
                  <span className="user_bold">{t('note')} </span>
                </p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12">
            <div className="row">
              <img className="img-fluid" src="./images/user_map.jpg" alt="" />
              <p className="user_text py-3 ">
                Once the location is provided you’ll be redirected to the google
                maps
              </p>
              <img
                className="img-fluid"
                src="./images/user_instructions.jpg"
                alt=""
              />
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export default index;
