import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import firebase from '../../firebase';

import PopupModal from './PopupModal';

function uploadSelfie({ setOpenModal, show }) {
  // const [showPhotoCertificate, setShowPhotoCertificate] = useState(false);

  const { t, i18n } = useTranslation();
  const [images, setImages] = React.useState([]);
  const [imgCount, setImgCount] = React.useState();
  const [screenSize, setScreenSize] = React.useState('');
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // const fetchCounts = async () => {
  //   const ref = firebase.firestore();
  //   const response = ref.collection('selfie_count');
  //   const dataC = await response.get();
  //   console.log(dataC);
  // };

  React.useEffect(() => {
    setScreenSize(window.innerWidth);
    const ref = firebase.firestore();
    // const dbC = firebase.firestore().collection('selfie_count');
    // dbC.get().then((res) =>
    //   res.forEach((value) => {
    //     setImgCount(value.data().count);
    //   })
    // );

    // Create a query against the collection
    const db = ref.collection('tiranga');

    db.where('moderated', '==', true)

      .orderBy('timestamp', 'desc')
      .limit(9)
      .get()
      .then((snapshot) => {
        const imageList = [];
        snapshot.forEach((value) => {
          // console.log(value.data());
          imageList.push(value.data());
        });
        setImages(imageList);
      });
  }, []);

  return (
    <>
      <div>
        <Container fluid>
          <Row className="mt-3">
            {/* <Col
              lg={6}
              sm={12}
              className="d-flex justify-content-center"
              // style={{ paddingLeft: screenSize > 970 && '5%' }}
            >
              <div className="">
                <h3 className="flag_title pt-lg-5 mb-4 topic-title">
                  {t('selfi_with_flag')}
                </h3>
                <p className="flag_text topic-desc">
                  {t('upload_selfie_text')}
                </p>
                <div className="row">
                  <div
                    className="about_btn col-lg-12 col-md-12"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <div
                      className=" row mt-5 mb-3"
                      style={{
                        backgroundColor: '#F97428',
                        height: 'auto',
                        width: '250px',
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      <div className="col-8 d-flex align-items-center">
                        <p className="btn_text mt-2 ">
                          {t('upload_selfie_button_text')}
                        </p>
                      </div>
                      <div className="col-4 py-2 d-flex align-items-center">
                        <img
                          className="img-fluid"
                          src="/images/arrow_btn.svg"
                          width="40px"
                          height="40px"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Row className="justify-content-md-center mt-3">
                  <Col className="selfi_counter">
                    <h3 className="total_selfi">{imgCount}</h3>
                    <p className="globe_text text-nowrap py-2">
                      {t('selfi_so_far')}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col> */}

            <Col lg={12} sm={12}>
              <Row>
                <h3 className="flag_title text-center  d-flex justify-content-center  pt-lg-5 mb-4 text-break">
                  {t('selfi_with_flag')}
                </h3>
              </Row>
              <Container
                className="selfie-cont"
                style={{ overflowY: 'hidden' }}
              >
                <Row
                  style={{ display: 'contents' }}
                // classsName={screenSize < 970 ? 'selfi-row' : 'self-row'}
                >
                  {(images.length >= 0
                    ? images
                    : t('images', {
                      returnObjects: true,
                    })
                  ).map((item, idx) => (
                    <Col
                      lg={4}
                      className="hashtagImg_sm"
                      style={{ display: 'inline-block' }}
                      key={Math.random()}
                    >
                      <div
                        style={{
                          borderRadius: '15px',
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(idx);
                        }}
                      >
                        <VillageImageDiv
                          style={{
                            backgroundColor: '#fff',
                            borderRadius: '15px',
                            backgroundSize: 'cover',
                            filter: 'blur(40px)',
                            height: '18vh',
                            // border: '1px solid black',
                            overflow: 'hidden',
                            backgroundImage: `url(${item.image_path})`,
                            cursor: 'pointer',
                          }}
                        />
                        <VillageImageDiv
                          className="th-border-top th-border-bottom"
                          style={{
                            borderRadius: '15px',
                            position: 'absolute',
                            top: '0%',
                            right: '0%',
                            // border: '2px solid transparent',
                            bottom: '0%',
                            backgroundSize: 'contain',
                            backgroundImage: `url(${item.image_path})`,
                            cursor: 'pointer',
                          }}
                        />
                        <div
                          className="p-2 name_text"
                          style={{
                            position: 'relative',
                            backgroundColor: '#1ca63b',
                            color: '#fff',
                            fontSize: '2vh',
                          }}
                        >
                          {(item?.user_name).toUpperCase()}
                        </div>
                      </div>
                    </Col>
                  ))}
                  {isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]?.image_path}
                      nextSrc={
                        images[(photoIndex + 1) % images.length]?.image_path
                      }
                      prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                          ?.image_path
                      }
                      imageCaption={(images[
                        photoIndex
                      ]?.user_name).toUpperCase()}
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + images.length - 1) % images.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                      }
                    />
                  )}

                  {/* {(images.length >= 0
                    ? images
                    : t('images', {
                        returnObjects: true,
                      })
                  ).map((item, idx) => (
                    <Col
                      lg={4}
                      className="hashtagImg_sm"
                      style={{ display: 'inline-block' }}
                      key={Math.random()}
                    >


                      <div
                        style={{
                          borderRadius: '15px',
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        <VillageImageDiv
                          style={{
                            backgroundColor: '#fff',
                            borderRadius: '15px',
                            backgroundSize: 'cover',
                            filter: 'blur(40px)',
                            height: '18vh',
                            // border: '1px solid black',
                            overflow: 'hidden',
                            backgroundImage: `url(${item.image_path})`,
                          }}
                        />
                        <VillageImageDiv
                          className="th-border-top th-border-bottom"
                          style={{
                            borderRadius: '15px',
                            position: 'absolute',
                            top: '0%',
                            right: '0%',
                            // border: '2px solid transparent',
                            bottom: '0%',
                            backgroundSize: 'contain',
                            backgroundImage: `url(${item.image_path})`,
                          }}
                        />
                         <div
                          className="p-2 name_text"
                          style={{
                            position: 'relative',
                            backgroundColor: '#1ca63b',
                            color: '#fff',
                            fontSize: '2vh',
                          }}
                        >
                          {(item?.user_name).toUpperCase()}
                        </div>
                      </div>
                    </Col>
                  ))} */}
                </Row>
              </Container>
              <Row className="justify-content-center text-center pt-3">
                <div className=" col-lg-12 col-md-12 read-more text-center">
                  <a href="/selfi">
                    <h4 className="readMoreText">{t('more_selfies')}</h4>
                  </a>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
        {/* {show && (
              <PopupModal
                setOpenModal={setModalOpen}
                showCertificate={showPhotoCertificate}
                setShowFlagCertificate={setShowPhotoCertificate}
              />
            )} */}
      </div>
    </>
  );
}

export default uploadSelfie;

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: '#555';
  background-position: center;
  border-radius: 20px;
`;
