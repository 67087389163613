import { React, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import '../PopupModal/PopupModal.css';
// import styled from 'styled-components';

// import download from '../../../assets/downloads.svg';
// import shares from '../../../assets/share.svg';
import CertificateFlag from '../../../assets/certi_flag.jpg';
import ShareModal from './ShareModal';
import Thankyou from './Thankyou';

function Certificate({
  name,
  share,
  setShowFlagCertificate,
  setShare,
  showThankyou,
  setShowThankyou,
}) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [imageUri, setUri] = useState(null);

  useEffect(() => {
    // if (localStorage.getItem("user")) {
    // Setup the Canvas
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.src = CertificateFlag;
    img.onload = function () {
      // Set Canvas resolution as per image
      canvas.width = img.width;
      canvas.height = img.height;
      canvas.crossOrigin = 'Anonymous';

      // Draw Image & Add Name
      ctx.font = 'bold 45pt Dancing Script, cursive';

      const userName = name[0].toUpperCase() + name.substring(1);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#bd8e33';
      ctx.textAlign = 'center';
      ctx.fillText(userName, canvas.width / 2, canvas.height / 2);

      // Show the Image  in the UI
      img.uri = canvas.toDataURL('image/jpg');
      setUri(img.uri);
      // };
    };
  }, []);

  return (
    <>
      <div style={{zIndex:1000}} className="modalBackground-certificate ">
        <div className="modal-container-certificate">
          <div className="popup-header-certificate">
            <div
              className="close_thankyou d-flex justify-content-end"
              onClick={() => {
                setShowFlagCertificate(false);
                setShowThankyou(true);
              }}
            >
              <img src="./images/x-circle.svg" alt="X" />
            </div>
            {/* <span
              className="float-right"
              onClick={() => {
                setShowFlagCertificate(false);
                setShowThankyou(true);
              }}
              style={{
                right: -10,
                top: -5,
                position: 'absolute',
                cursor: 'pointer',
              }}
            >
              X
            </span> */}
            <canvas id="canvas" className="certificate-canvas">
              {' '}
            </canvas>
          </div>
          <div>
            <div className="row">
              <div
                className="col text-center orange  cursor-pointer"
                onClick={() => {
                  const link = document.createElement('a');
                  link.download = name;
                  link.href = imageUri;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                <img
                  className="share pb-2"
                  src="./images/download.svg"
                  alt="Download"
                />
                <p>{t('download')}</p>
              </div>
              <div
                className="col text-center green cursor-pointer"
                onClick={() => {
                  setShowFlagCertificate(false);
                  setShare(true);
                }}
              >
                <img
                  className="share pb-2"
                  src="./images/share.svg"
                  alt="Share"
                />
                <p>{t('share')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {share && <ShareModal imageUri={imageUri} setShare={setShare} />}
    </>
  );
}

export default Certificate;
