import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Layout from '../../components/Layout';
import Footer from '../../components/Navigation/Footer';

function PrivacyPolicy() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <Layout>
      <div className="container my-4">
        <div className="row">
          <div className="col text-center">
            <div className="h2 my-5" style={{ marginTop: '60px' }}>
              {t('privacy_policy_title')}
            </div>
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('privacy_policy_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('consent')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('consent_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('information')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('information_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('use_info')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('use_info_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('website_visitor')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('website_visitor_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('log_files')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('log_files_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('web_beacons')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('web_beacons_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('adv_partner')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('adv_partner_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('third_party')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('third_party_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('security')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('security_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('aggregated_statistics')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('aggregated_statistics_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('e_commerce')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('e_commerce_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('children_info')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('children_info_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('privacy_policy_changes')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('privacy_policy_changes_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('laws_and_regulations')}
          </div>
        </div>{' '}
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('laws_and_regulations_text')}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
