import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';
import { useTranslation, Trans } from 'react-i18next';

import '../PopupModal/PopupModal.css';

function ShareModal({ imageUri, setShare, setShowThankyou, showThankyou }) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer-share" style={{ width: 'auto' }}>
          <div className="popup-header-certificate">
            <div className="popupText-share">{t('share_with_community')}</div>
          </div>
          <div className="share-div-icon">
            <FacebookShareButton
              url="harghartiranga.com"
              image={imageUri}
              quote="Let's come forward and contribute our part in pinning our Indian flag and putting a selfie with it."
              hashtag="harghartiranga"
            >
              <FacebookIcon size="2.5rem" round />
            </FacebookShareButton>
            <LinkedinShareButton
              url="harghartiranga.com"
              image={imageUri}
              title="Let's come forward and contribute our part in pinning our Indian flag and putting a selfie with it."
              summary="harghartiranga"
              source="harghartirana.in"
            >
              <LinkedinIcon size="2.5rem" round />
            </LinkedinShareButton>
            <TwitterShareButton
              title="Let's come forward and contribute our part in pinning our Indian flag and putting a selfie with it."
              url="harghartiranga.com"
              image={imageUri}
              hashtags={['harghartiranga']}
            >
              <TwitterIcon size="2.5rem" round />
            </TwitterShareButton>
            <WhatsappShareButton
              title="Let's come forward and contribute our part in pinning our Indian flag and putting a selfie with it."
              url="harghartiranga.com"
              image={imageUri}
              hashtags={['harghartiranga']}
            >
              <WhatsappIcon size="2.5rem" round />
            </WhatsappShareButton>
            
          </div>
          <div
            className="skip d-flex justify-content-center"
            onClick={() => {
              setShare(false);
              setShowThankyou(true);
            }}
          >
            <p>{t('close')}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShareModal;
