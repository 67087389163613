import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import paths from 'pages/Router/paths';
import Layout from '../../components/Layout';
import data from '../../assets/data/data.json';
import Footer from '../../components/Navigation/Footer';

function blog() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <Layout>
        {/* <img className="img-fluid" src="images/about_intro.jpg" alt="" /> */}
        <Container
          // className="child"
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'./images/bg_img1.png'})`,
            backgroundRepeat: 'no-repeat',
            fontFamily: 'sans-serif',
            overflow: 'hidden',
            // height: '80vh',
            // display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row className="about-section align-items-center">
            <Col
              lg={12}
              sm={12}
              style={{ marginTop: '10%', marginBottom: '5%' }}
            >
              <div className="about">
                <h3 className="galary_header">{t('about_the_flag')}</h3>
                <p className="galary_text p-2">{t('about_the_flag1')}</p>
                <p className="galary_text p-2">{t('about_the_flag2')}</p>
                {/* <p className="galary_text p-2">{t('about_the_flag3')}</p> */}
              </div>
            </Col>
          </Row>

          <Row className="about-section align-items-center mb-5 blog-content">
            {data?.map((item, index) => {
              return (
                <Col
                  className="d-flex justify-content-center mb-2"
                  key={item?.id}
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  {/* <Link
                    to={{
                      pathname: `/blog/${item?.id}`,
                      query: { title: item?.id },
                    }}
                    state={{ from: 'occupation' }}
                  > */}
                  <Card
                    className="category-card"
                    onClick={() => {
                      // console.log(`/blog/${item?.id}`);
                      // <Redirect to={paths.BLOG_TOPIC} />;
                      // history.push(`/blog/${item?.id}`);
                      history.push({
                        pathname: `/blog/${item?.id}`,
                        state: {
                          id: item?.id,
                          title: item?.title,
                          desc: item?.desc,
                          img_url: item?.img_url,
                        },
                      });
                    }}
                  >
                    <Card.Img className="category-img" src={item?.img_url} />

                    <Card.Body className="white pt-4">
                      <Card.Title className="categoryCard-title  length-chars">
                        {item?.title}
                      </Card.Title>
                      <Card.Text className="categoryCard-para length-chars">
                        {item?.desc}
                      </Card.Text>
                      <p
                        className="pt-3 "
                        style={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Read more...
                      </p>
                    </Card.Body>
                  </Card>
                  {/* </Link> */}
                  {/* <Card className="about-card" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={item?.img_url} />
                    <Card.Body>
                      <Card.Title className="title-card length-chars">
                        {item?.title}
                      </Card.Title>
                      <Card.Text className="title-desc">{item?.desc}</Card.Text>
                    </Card.Body>
                  </Card> */}
                </Col>
              );
            })}
          </Row>
        </Container>
      </Layout>
    </>
  );
}

export default blog;
