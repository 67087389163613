import React from 'react';
import Layout from 'components/Layout';
import { useTranslation, Trans } from 'react-i18next';

import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../../components/Navigation/Footer';
import flagSupplier from '../../assets/data/flag_supplier.json';

function index() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [vendors, setVendors] = React.useState([
    {
      name: 'JAI PADAM SHREE TRADEX PVT LTD, NEW DELHI',
      contact: 'Jai Singh Sharma- 9818376578',
    },
    {
      name: 'GADIA ENTERPRISES, SURAT, GUJARAT',
      contact: 'Arvind Gadia- 9879594885',
    },
    {
      name: 'UDDIN ENTERPRISED, KOLKATA',
      contact: 'Saluadin Mandal-9748085513, 8670265010',
    },
    {
      name: 'FAIRDEAL CORPORATION, SURAT',
      contact: 'Keyur Govani- 9925000105',
    },
    { name: 'KUSH LINKERS, AHMEDABAD', contact: 'Vijay Chhabria- 9825586342' },
    {
      name: 'FABRIC FOUNDRY, GURGAON',
      contact: 'Ankit Ray- 8700092277, 9205900131',
    },
    {
      name: 'MENDRO CORPORATION PVT LTD, GAZIABAD',
      contact: 'Arun Gopal-7210273000',
    },
    {
      name: 'SIDDHIVINAYAK KNOTS AND PRINTS PVT LTD',
      contact: 'Rakesh Kumar Sarawagi- 0261-2894415',
    },
    { name: 'GOYAL TRADERS', contact: 'Pratik Goyal- 9909159942' },
    {
      name: 'NIPUN TECHNOWEAVES PVT LTD, SURAT',
      contact: 'Anil Roongta- 9328030520',
    },
    { name: 'NONA LIFE STYLE, SURAT', contact: 'Ankit Rajput- 9205900131' },
  ]);

  return (
    <div className="">
      <Layout>
        <div className="container my-4">
          <div className="row">
            <div className="col text-center">
              <div className="h4 my-5" style={{ marginTop: '60px' }}>
                {t('flag_suppliers1')}
              </div>
            </div>
          </div>
          {vendors.map((vendor) => {
            return (
              <div className="mx-4">
                <div className="row text-bold ">
                  <div className="col-6 float-right p-3  border rounded">
                    <span className="mx-2 table_text">{vendor.name}</span>
                  </div>
                  <div className="col-6 float-left p-3  border">
                    <span className="mx-2 table_text">{vendor.contact}</span>
                  </div>
                </div>
              </div>
            );
          })}

          <br />
          <div className="row">
            <div className="col text-center">
              <div className="h4 my-5" style={{ marginTop: '60px' }}>
                {t('flag_suppliers2')}
              </div>
            </div>
          </div>

          {flagSupplier.map((suppliers) => {
            return (
              <div className="mx-4">
                <div className="row text-bold ">
                  <div className="col-4  p-2  border rounded">
                    <span className=" table_text">{suppliers.Name}</span>
                  </div>
                  <div className="col-4  p-2  border">
                    <span className="mx-2 text-break table_text">
                      {suppliers.Email}
                    </span>
                  </div>
                  <div className="col-4 p-2  border">
                    <span className="mx-2 text-break table_text">
                      {suppliers.Mobile}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
}

export default index;
