/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/self-closing-comp */
/* eslint-disable radix */
import axios from 'axios';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import newflagImage from '../../assets/new_flag.png';
import Certificate from './Certificate/Certificate';
import ShareModal from './Certificate/ShareModal';
import Thankyou from './Certificate/Thankyou';
import PopupModal from './PopupModal/PopupModal';
import flagImage from '../../assets/Flag_India.png';


function index() {



    const [currentLang, setCurrentLang] = React.useState(
        i18n.language ? i18n.language : 'en'
      );
      useEffect(() => {
        setCurrentLang(i18n.language);
      }, [i18n.language]);

    const [token, setToken] = React.useState('');
    const [map, setMap] = React.useState(null);
    const [dataSet, setDataset] = React.useState("harghar_tiranga");
    
    const [showFlagCertificate, setShowFlagCertificate] = React.useState(false);
    const [share, setShare] = React.useState(false);
    const [showThankyou, setShowThankyou] = React.useState(false);
  
    const [modalOpen, setModalOpen] = React.useState(false);
    const [DetUser, setDetUser] = React.useState(
        localStorage.getItem('HGT_USER')
          ? JSON.parse(localStorage.getItem('HGT_USER'))
          : undefined
      );

    const [uname, setUname] = React.useState('');
    const [uImage, setImage] = React.useState('');

    const [popup, setPopup] = React.useState(null);
  
    useEffect(() => {
      if (DetUser) {
        console.log(DetUser);
        setUname(DetUser.name);
        setImage(DetUser.image);
      }
    }, [DetUser]);

   const authTokenGenerate=() =>{

    axios.get("https://asia-south1-hht-website-v2.cloudfunctions.net/mapMyIndiaToken").then(res => {
        console.log(res.data);
        setToken(res.data.access_token);
    }
    ).catch(err => {
        console.log(err);
    }
    );
 
   };

   const getMap=()=>{
    console.log(map);
    const obj = {
        request: "GetMap", access_token: `Bearer ${token}`, datasetNme: dataSet, height: window.innerHeight,
        width: window.innerWidth, bbox: map.getBounds().toBBoxString(), service: 'WMS', version: '1.1.1', format: 'image/png', transparent: true, srs: 'EPSG:3857'
    };
    const ly = window.L.WMS.overlay("https://mgis-api.mappls.com/getMap", obj);
    map.addLayer(ly, true);
};


const pinFlag=(pos)=>{

    const data = new FormData();

    const geoJSON={type:"Feature",
    geometry:{
        type:"Point",
        coordinates:[pos.lng,pos.lat]},
        properties:{
            Citizen:uname,
            uImage:"https://mmi-api-team.s3.ap-south-1.amazonaws.com/API-Team/harGharTiranga/flag.png"
        }
    };


data.append('appendStatus', 'true');
data.append('datasetNme', dataSet);
data.append('geoJSON', JSON.stringify(geoJSON));

const config = {
  method: 'post',
  url: 'https://mgis-api.mapmyindia.com/saveDataset/object',
  headers: { 
    'accept': 'application/json', 
    'Authorization': `bearer ${token}`, 
    'Content-Type': 'multipart/form-data'
  },
  // eslint-disable-next-line object-shorthand
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});



};


    useEffect(() => {

        if(map){
            getMap();
            // const infoWindow = new window.L.InfoWindow({
            //     // eslint-disable-next-line object-shorthand
            //     map: map, // mapObject to plot infowindow
            //     position: map.getCenter(), // position of infowindow
      
            //     content: 'Locating..', // Content of infowindow
            //     // Opotional Parameters
            //     // class: info_class, //class
            //     offset: [0, 10], // Offset
            //     maxWidth: 300, // Max Width of popup
            //     closeButton: true, // Close button on infoWindow
            //   });

            const infoWindow = new window.L.Popup();


            const notifier = document.createElement('div');
            notifier.classList.add('notifier');
            notifier.classList.add('flag_btn');
            notifier.classList.add('row');
            notifier.classList.add('mb-5');
            notifier.style.zIndex = 1000;
            notifier.classList.add('col-md-12');
    
            const paragraph = document.createElement('p');
            paragraph.innerText = 'Fetching...';
            paragraph.classList.add('btn_txt');
            paragraph.style.color = 'white';
            {
              currentLang.startsWith('mal') || currentLang.startsWith('tam')
                ? (paragraph.style.fontSize = '1.5vh')
                : (paragraph.style.fontSize = '1.9vh');
            }
    
            paragraph.classList.add('m-2');
            paragraph.classList.add('p-2');
            paragraph.classList.add('text-center');
    
            const Text = document.createElement('div');
            Text.appendChild(paragraph);
    
            notifier.id = 'notifier';
            notifier.appendChild(Text);
            notifier.style.backgroundImage = `url(${'./images/org_btn.svg'})`;
            notifier.style.backgroundSize = 'contain';
            notifier.style.backgroundRepeat = 'no-repeat';
            notifier.style.backgroundPosition = 'center';
            notifier.style.borderRadius = '10px';
            notifier.style.width = '50%';
            notifier.style.height = 'auto';
            notifier.style.display = 'inline-block';
            notifier.style.position = 'fixed';
            notifier.style.fontWeight = '600';
            notifier.style.cursor = 'pointer';
            notifier.style.marginLeft = '25%';
            notifier.style.marginRight = '25%';
    
            document.getElementById('map').appendChild(notifier);
    
            // mapObject.controls[window?.mappls?.ControlPosition?.BOTTOM_CENTER].push(
            //   notifier
            // );
    
            if (navigator.geolocation) {
              console.log('Location Found');
              paragraph.innerText = currentLang.startsWith('en')
                ? 'FETCHING LOCATION'
                : currentLang.startsWith('hin')
                  ? 'स्थान खोजना जारी'
                  : currentLang.startsWith('mar')
                    ? 'स्थान शोधले जात आहे'
                    : currentLang.startsWith('pun')
                      ? 'ਲੋਕੇਸ਼ਨ ਪ੍ਰਾਪਤ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ'
                      : currentLang.startsWith('as')
                        ? 'স্থান প্ৰাপ্ত কৰা'
                        : currentLang.startsWith('tel')
                          ? 'మీరుండే ప్రదేశాన్ని వెతుకుతోంది'
                          : currentLang.startsWith('ben')
                            ? 'অবস্থান আনয়ন করা হচ্ছে'
                            : currentLang.startsWith('tam')
                              ? 'இருப்பிடத்தைக் கொண்டு வருகிறது '
                              : currentLang.startsWith('guj')
                                ? 'લોકેશન મેળવી રહ્યું છે'
                                : currentLang.startsWith('kan')
                                  ? 'ಸ್ಥಳವನ್ನು ಪಡೆಯಲಾಗುತ್ತಿದೆ'
                                  : currentLang.startsWith('mal')
                                    ? 'ലൊക്കേഷൻ ലഭ്യമാക്കുന്നു'
                                    : currentLang.startsWith('od')
                                      ? 'ଲୋକେସନ୍ ପ୍ରାପ୍ତ କରୁଛି'
                                      : 'FETCHING LOCATION';
             // setIsLocating(true);
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  };

                  paragraph.innerText = currentLang.startsWith('en')
                    ? 'LOCATION FOUND'
                    : currentLang.startsWith('hin')
                      ? 'स्थान प्राप्त'
                      : currentLang.startsWith('mar')
                        ? 'स्थान मिळाले'
                        : currentLang.startsWith('pun')
                          ? 'ਲੋਕੇਸ਼ਨ ਮਿਲ ਗਿਆ'
                          : currentLang.startsWith('as')
                            ? 'অৱস্থান পোৱা গৈছে'
                            : currentLang.startsWith('tel')
                              ? 'స్థానం కనుగొనబడింది'
                              : currentLang.startsWith('ben')
                                ? 'অবস্থান পাওয়া গেছে'
                                : currentLang.startsWith('tam')
                                  ? 'இருப்பிடம் கண்டறியப்பட்டது'
                                  : currentLang.startsWith('guj')
                                    ? 'લોકેશન મળી ગયું છે'
                                    : currentLang.startsWith('kan')
                                      ? 'ಸ್ಥಳ ಕಂಡುಬಂದಿದೆ'
                                      : currentLang.startsWith('mal')
                                        ? 'ലൊക്കേഷൻ ലഭ്യമാക്കുക'
                                        : currentLang.startsWith('od')
                                          ? 'ଲୋକେସନ୍ ପ୍ରାପ୍ତ ହେଲା'
                                          : 'LOCATION FOUND';
    
                  setTimeout(() => {
                    paragraph.innerText = currentLang.startsWith('en')
                      ? 'PIN A FLAG'
                      : currentLang.startsWith('hin')
                        ? 'ध्वज फहराएँ'
                        : currentLang.startsWith('mar')
                          ? 'ध्वज पिन करा'
                          : currentLang.startsWith('pun')
                            ? 'ਇੱਕ ਝੰਡਾ ਪਿੰਨ ਕਰੋ'
                            : currentLang.startsWith('as')
                              ? 'এখন পতাকা পিন কৰক'
                              : currentLang.startsWith('tel')
                                ? 'జెండాను పిన్ చేయండి'
                                : currentLang.startsWith('ben')
                                  ? 'একটি পতাকা পিন করুন'
                                  : currentLang.startsWith('tam')
                                    ? 'கொடியைக் குத்துங்கள்'
                                    : currentLang.startsWith('guj')
                                      ? 'ધ્વજ લગાવો'
                                      : currentLang.startsWith('kan')
                                        ? 'ಧ್ವಜವನ್ನು ಪಿನ್ ಮಾಡಿ'
                                        : currentLang.startsWith('mal')
                                          ? 'ഒരു പതാക പിൻ ചെയ്യുക'
                                          : currentLang.startsWith('od')
                                            ? 'ଗୋଟିଏ ପତାକା ପିନ୍ କରନ୍ତୁ'
                                            : 'PIN A FLAG';
                    notifier.addEventListener('click', () => {
                        console.log(infoWindow);
                      pinFlag(pos);
                      notifier.hidden = true;
                     // infoWindow.close();
                    });
                   
                  }, 1000);
                //   console.log(infoWindow.setPosition);
                   infoWindow.setLatLng(pos).openOn(map);
                     map.setView(pos, 18);
                  {
                    currentLang.startsWith('en')
                      ? infoWindow.setContent('LOCATION FOUND')
                      : currentLang.startsWith('hin')
                        ? infoWindow.setContent('स्थान प्राप्त')
                        : currentLang.startsWith('mar')
                          ? infoWindow.setContent('स्थान मिळाले')
                          : currentLang.startsWith('pun')
                            ? infoWindow.setContent('ਲੋਕੇਸ਼ਨ ਮਿਲ ਗਿਆ')
                            : currentLang.startsWith('as')
                              ? infoWindow.setContent('অৱস্থান পোৱা গৈছে')
                              : currentLang.startsWith('tel')
                                ? infoWindow.setContent('స్థానం కనుగొనబడింది')
                                : currentLang.startsWith('ben')
                                  ? infoWindow.setContent('অবস্থান পাওয়া গেছে')
                                  : currentLang.startsWith('tam')
                                    ? infoWindow.setContent('இருப்பிடம் கண்டறியப்பட்டது')
                                    : currentLang.startsWith('guj')
                                      ? infoWindow.setContent('લોકેશન મળી ગયું છે')
                                      : currentLang.startsWith('kan')
                                        ? infoWindow.setContent('ಸ್ಥಳ ಕಂಡುಬಂದಿದೆ')
                                        : currentLang.startsWith('mal')
                                          ? infoWindow.setContent('ലൊക്കേഷൻ കണ്ടെത്തി')
                                          : currentLang.startsWith('od')
                                            ? infoWindow.setContent('ଲୋକେସନ୍ ପ୍ରାପ୍ତ ହେଲା')
                                            : 'LOCATION FOUND';
                  }
                  // infoWindow.open(map);
    
                //   map.setCenter(pos);
                  // mapObject.setTilt(45);
                //   map.setZoom(18);
                  // smoothZoom(mapObject, 18, mapObject.getZoom(), setIsLocated);
    
                 // setIsLocating(false);
                },
                () => {
                  //  handleLocationError(true, infoWindow, map.getCenter());
                //   map.setCenter(map.getCenter());
    
                //   map.setCenter(map.getCenter());
                  infoWindow.setLatLng(map.getCenter());
                  infoWindow.openOn(map);
                  {
                    currentLang.startsWith('en')
                      ? infoWindow.setContent('ERROR FETCHING LOCATION')
                      : currentLang.startsWith('hin')
                        ? infoWindow.setContent('स्थान खोजने में त्रुटि')
                        : currentLang.startsWith('mar')
                          ? infoWindow.setContent('स्थान शोधण्यात त्रुटी')
                          : currentLang.startsWith('pun')
                            ? infoWindow.setContent('ਲੋਕੇਸ਼ਨ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਏਰਰ')
                            : currentLang.startsWith('as')
                              ? infoWindow.setContent('স্থান প্ৰাপ্ত কৰোঁতে ত্ৰুটি')
                              : currentLang.startsWith('tel')
                                ? infoWindow.setContent('స్థానాన్ని పొందడంలో లోపం')
                                : currentLang.startsWith('ben')
                                  ? infoWindow.setContent('অবস্থান আনয়নে ত্রুটি')
                                  : currentLang.startsWith('tam')
                                    ? infoWindow.setContent(
                                      'இருப்பிடத்தைக் கொண்டு வருவதில் பிழை ஏற்பட்டது'
                                    )
                                    : currentLang.startsWith('guj')
                                      ? infoWindow.setContent('લોકેશન મેળવવામાં ભૂલ')
                                      : currentLang.startsWith('kan')
                                        ? infoWindow.setContent('ಸ್ಥಳವನ್ನು ಪಡೆಯುವಲ್ಲಿ ದೋಷ')
                                        : currentLang.startsWith('mal')
                                          ? infoWindow.setContent('ലൊക്കേഷൻ ലഭ്യമാക്കുന്നതിൽ പിശക്')
                                          : currentLang.startsWith('od')
                                            ? infoWindow.setContent('ଲୋକେସନ୍ ପ୍ରାପ୍ତିରେ ତ୍ରୁଟି')
                                            : 'ERROR FETCHING LOCATION';
                  }
                //   infoWindow.setPosition(map.getCenter());
                //   infoWindow.open(map);
                  // setIsLocating(false);
                },
                { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
              );
            } else {
              // Browser doesn't support Geolocation
              // handleLocationError(false, infoWindow, map.getCenter());
            //   map.setCenter(map.getCenter());
            //   {
            //     currentLang.startsWith('en')
            //       ? infoWindow.setContent('Browser does not support Geolocation')
            //       : infoWindow.setContent(
            //         'ब्राउज़र जियोलोकेशन का समर्थन नहीं करता'
            //       );
            //   }
            //   // infoWindow.setContent('Browser does not support Geolocation');
    
            //   infoWindow.setPosition(map.getCenter());
            //   infoWindow.open(map);
             // setIsLocating(false);
            }






        //    const keys=Object.keys(data);
        //     const values=Object.values(data);
        //     for (let i=0;i<keys.length;i+1){
        //         // eslint-disable-next-line no-new
        //         new window.mappls.Marker({
        //             position: new window.mappls.LatLng(values[i].lat,values[i].lng),
        //             map,
        //             fitboundOptions:
        //             {
        //               padding:  120,
        //               duration:1000
        //             },
        //             clusters: true,
        //         fitbounds: true,
        //             icon:newflagImage,
                    
        //         });
                
        //     }
        }



    }, [map]);




    useEffect(() => {

        if(map){
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition(position => {
                    const {latitude, longitude} = position.coords;
                    map.setView([latitude, longitude], 19);
                    
                }
                );
            }
        }
    
    }, [map]);



   const mapLoad=() =>{
   const mapp = new window.MapmyIndia.Map('map', {
        center: [28.69307277, 77.1976126],
        zoom: 5,
        search: true,
        coronaLink: false,
        maxZoom: 19
    });
    setMap(mapp);
    
    // mapp.on('click',  (e)=>{
    //     console.log(e);
    //     const {x} = e.layerPoint;
    //     const {y} = e.layerPoint;
       
    //     axios.get(`https://mgis-api.mappls.com/getFeatureInfo?datasetNme=${dataSet}
    //     &transparent=true&bbox=${mapp.getBounds().toBBoxString()}&width=${window.innerWidth}
    //     &height=${window.innerHeight}&srs=EPSG:4326&x=${parseInt(x)}&y=${parseInt(y)}`,
    //     {'headers':{"Authorization": `Bearer ${token}`}},{crossOriginIsolated:true}).then(response=>{
    //         console.log(response);
    //     }).catch(error=>{
    //         console.log(error);
    //     });
    //     setModalOpen(true);
    //     // var settings = {
    //     //     "url": "https://mgis-api.mappls.com/getFeatureInfo?datasetNme=" + dataSet + "&transparent=true&bbox=" + map.getBounds().toBBoxString() + "&width=" + window.innerWidth + "&height=" + window.innerHeight + "&srs=EPSG:4326&x=" + parseInt(x) + "&y=" + parseInt(y),
    //     //     "method": "GET",
    //     //     "timeout": 0,
    //     //     "headers": {
    //     //         "Authorization": "Bearer " + token
    //     //     },
    //     // };
    //     // $.ajax(settings).done(function (response) {
    //     //     console.log(response);
    //     // });
    // });

   };

        // $.ajax({
        //     data: {
        //         "grant_type": "client_credentials",
        //         "client_id": "33OkryzDZsIFC66W6sLoIFrhzwEgCj44vGGf7JVVnx17y5m1B167qedQ-I2HxnY3n1cNNAHA0cmVZweDrzk8Gw==",
        //         "client_secret": "lrFxI-iSEg9ZlKltPuVrT8nPTL8ROT0lb03TAbm4VsHaI3fbPDGCLUPaVskEs57hCcsiX3lXIiV_uGH4N9bpc74-YNKDp01I"
        //     },
        //     headers: {
        //         "Content-Type": "application/x-www-form-urlencoded",
        //     },
        //     method: 'POST',
        //     crossDomain: true,
        //     async: true,
        //     url: 'https://outpost.mappls.com/api/security/oauth/token',
        //     error: function (data) {
        //         console.log(data);
        //         alert('An error has occurred');
        //     },
        //     success: function (data) {
        //         call(data);
        //     }
        // });
    // }

    // Auth_token_generate(function (data) {
    //     token = data.access_token;
    //     scriptLoad(token);
    // })


    useEffect(() => {
        console.log('index2');
        authTokenGenerate();
    } , []);

    useEffect(() => {
       if(token!==''){
        const script = document.createElement("script");
            script.src = `https://apis.mappls.com/advancedmaps/v1/${token}/map_load?v=1.5&plugin=geoanalytics`;
            script.onload = function () {
                mapLoad();
            };
            document.documentElement.firstChild.appendChild(script);
       }
    } , [token]);



  return (
    // eslint-disable-next-line react/self-closing-comp
   <Layout>
  {modalOpen && (
          <PopupModal
            setOpenModal={setModalOpen}
            showCertificate={showFlagCertificate}
            setShowFlagCertificate={setShowFlagCertificate}
            setShowThankyou={setShowThankyou}
            showThankyou={showThankyou}
            image={uImage}
            name={uname}
          />
        )}

        {showFlagCertificate && (
          <Certificate
            image={uImage || flagImage}
            name={uname}
            setShowFlagCertificate={setShowFlagCertificate}
            setShowThankyou={setShowThankyou}
            share={share}
            setShare={setShare}
          />
        )}

        {share && (
          <ShareModal
            setShowThankyou={setShowThankyou}
            showThankyou={showThankyou}
            setShare={setShare}
          />
        )}
        {showThankyou && (
          <Thankyou
            setShowThankyou={setShowThankyou}
            showThankyou={showThankyou}
            currentLang={currentLang}
          />
        )}
     <div style={{ height: '95vh',width: '100vw',zIndex:0 }} id="map"></div>
   
   </Layout>
  );
}

export default index;