import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import paths from 'pages/Router/paths';
import Layout from '../../components/Layout';
import data from '../../assets/data/data.json';
import Footer from '../../components/Navigation/Footer';

function SalientFeatures() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <Layout>
        {/* <img className="img-fluid" src="images/about_intro.jpg" alt="" /> */}
        <Container
          // className="child"
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'./images/bg_img1.png'})`,
            backgroundRepeat: 'no-repeat',
            fontFamily: 'sans-serif',
            overflow: 'hidden',
            // height: '80vh',
            // display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row className="about-section align-items-center">
            <Col
              lg={12}
              sm={12}
              style={{ marginTop: '10%', marginBottom: '5%' }}
            >
              <div className="about">
                <h3 className="galary_header">
                  Salient Features of Flag Code of India, 2002
                </h3>

                <p className="galary_text p-2">
                  1. The Indian National Flag represents the hopes and
                  aspirations of the people of India. It is the symbol of our
                  national pride and there is universal affection and respect
                  for, and loyalty to, the National Flag. It occupies a unique
                  and special place in the emotions and psyche of the people of
                  India.{' '}
                </p>
                <p className="galary_text p-2">
                  2. The hoisting/use/display of the Indian National Flag is
                  governed by the Prevention of Insults to National Honour Act,
                  1971 and the Flag Code of India, 2002. Some of salient
                  features of the Flag Code of India, 2002 are listed below for
                  the information of the public:-
                </p>
                <p className="galary_text p-2">
                  a) The Flag Code of India, 2002 was amended vide Order dated
                  30st December, 2021 and National Flag made of polyester or
                  machine made Flag have been allowed. Now, the National Flag
                  shall be made of hand spun and hand woven or machine made,
                  cotton/polyester/wool/silk khadi bunting.{' '}
                </p>
                <p className="galary_text p-2">
                  b) A member of public, a private organization or an
                  educational institution may hoist/display the National Flag on
                  all days and occasions, ceremonial or otherwise, consistent
                  with the dignity and honour of the National Flag.{' '}
                </p>
                <p className="galary_text p-2">
                  c) The Flag Code of India, 2002 was amended vide Order dated
                  19th July, 2022 and clause (xi) of paragraph 2.2 of Part-II of
                  the Flag Code of India was replaced by the following clause:-
                  (xi) “where the Flag is displayed in open or displayed on the
                  house of a member of public, it may be flown day and night;”
                </p>
                <p className="galary_text p-2">
                  d) The National Flag shall be rectangular in shape. The Flag
                  can be of any size but the ratio of the length to the height
                  (width) of the Flag shall be 3:2.
                </p>
                <p className="galary_text p-2">
                  e) Whenever the National Flag is displayed, it should occupy
                  the position of honour and should be distinctly placed.
                </p>
                <p className="galary_text p-2">
                  f) A damaged or dishevelled Flag shall not be displayed.{' '}
                </p>
                <p className="galary_text p-2">
                  g) The Flag should not be flown from a single masthead
                  simultaneously with any other flag or flags.{' '}
                </p>
                <p className="galary_text p-2">
                  h) The Flag should not be flown on any vehicle except of the
                  dignitaries mentioned in Section IX of Part III of the Flag
                  Code, such as President, Vice-President, Prime-Minister,
                  Governors etc.{' '}
                </p>
                <p className="galary_text p-2">
                  i) No other flag or bunting should be placed higher than or
                  above or side by side with the National Flag.{' '}
                </p>
                <p className="galary_title p-2">
                  Note:- For further details, the Prevention of Insults to
                  National Honour Act, 1971 and the Flag Code of India, 2002 are
                  available on Ministry of Home Affairs’ website www.mha.gov.in.{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}

export default SalientFeatures;
