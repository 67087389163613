import React from 'react';

// import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import JustifiedGrid from 'react-justified-grid';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Container, Row, Col } from 'react-bootstrap';
import { uuid } from 'uuid';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useTranslation, Trans } from 'react-i18next';
import data from '../../assets/data.json';
import firebase from '../../firebase';

// const getItems = () =>
//   Array(20)
//     .fill(0)
//     .map((_, ind) => ({ id: `element-${ind}` }));
// const Arrow = ({ text, className }) => {
//   return <div className={className}>{text}</div>;
// };
// function LeftArrow() {
//   const { isFirstItemVisible, scrollPrev } = React.useContext(
//     VisibilityContext
//   );

//   return (
//     <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
//       Left
//     </Arrow>
//   );
// }

// function RightArrow() {
//   const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

//   return (
//     <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
//       Right
//     </Arrow>
//   );
// }

// function Card({ onClick, selected, title, itemId }) {
//   const visibility = React.useContext(VisibilityContext);

//   return (
//     <div
//       onClick={() => onClick(visibility)}
//       style={{
//         width: '160px',
//       }}
//       // tabIndex={0}
//     >
//       <div className="card">
//         <div>{title}</div>
//         <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
//         <div>selected: {JSON.stringify(!!selected)}</div>
//       </div>
//     </div>
//   );
// }

// function TwitterWall() {
//   const [items, setItems] = React.useState(getItems);
//   const [selected, setSelected] = React.useState([]);
//   const [position, setPosition] = React.useState(0);

//   const isItemSelected = (id) => !!selected.find((el) => el === id);

//   const handleClick = (id) => ({ getItemById, scrollToItem }) => {
//     const itemSelected = isItemSelected(id);

//     setSelected((currentSelected) =>
//       itemSelected
//         ? currentSelected.filter((el) => el !== id)
//         : currentSelected.concat(id)
//     );
//   };

//   return (
//     <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
//       {items.map(({ id }) => (
//         <Card
//           itemId={id} // NOTE: itemId is required for track items
//           title={id}
//           key={id}
//           onClick={handleClick(id)}
//           selected={isItemSelected(id)}
//         />
//       ))}
//     </ScrollMenu>
//   );
// }

function TwitterWall() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [tweets, setTweets] = React.useState([]);

  React.useEffect(() => {
    const db = firebase.firestore().collection('tweet');
    db.orderBy('timestamp_ms', 'desc')
      .limit(8)
      .get()
      .then((snapshot) => {
        const list = [];
        snapshot.forEach((doc) => {
          list.push(doc.data().id_str);
          // console.log(doc.data().id_str);
        });
        setTweets([...new Set(list)]);
        //  console.log(list);
      });
  }, []);

  return (
    <div>
      <div
        className="hashtag-div"

        // style={{ marginRight: '10%' }}
      >
        <h3 className="hashtag-title pt-lg-5 mt-3 d-flex justify-content-center">
          #harghartiranga
        </h3>
      </div>
      <Container className="tweet-cont mx-2" fluid style={{ width: '96vw' }}>
        <Row className="tweet-row" style={{ display: 'contents' }}>
          {tweets.map((item, idx) => (
            <Col
              xl={3}
              lg={3}
              md={4}
              sm={1}
              key={item}
              className="hashtagImg_sm "
              style={{ display: 'inline-block' }}
            >
              {/* <div
                style={{
                  backgroundImage: `url(${item})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  // textAlign: 'center',
                  backgroundRepeat: 'no-repeat',
                  // overflowY: 'scroll',
                  padding: 'none',
                  overflowX: 'hidden',
                  height: '200px',
                  color: '#C1A56E',
                  borderRadius: '5px',
                }}
                src={item}
                alt="img"
                onClick={() => {
                  console.log('helloooooooooo');
                }}
              /> */}

              <TwitterTweetEmbed
                className=""
                tweetId={item}
                options={{
                  cards: 'hidden',
                  hideCard: true,
                  hideThread: false,
                  maxWidth: 500,
                  width: 450,
                  // minWidth: '30vw',
                }}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default TwitterWall;
