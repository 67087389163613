import {
  React,
  useState,
  useRef,
  useEffect,
  Fragment,
  createElement,
  Children,
  isValidElement,
  cloneElement,
} from 'react';

import { useFormatMessage } from 'hooks';
import { Container, Row, Col, section } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Wrapper } from '@googlemaps/react-wrapper';
import { useTranslation, Trans } from 'react-i18next';
import { createCustomEqual } from 'fast-equals';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
// import bg from './bg_img.png';
import { v4 as uuidv4 } from 'uuid';
import Lottie from 'react-lottie';
import Globe1 from './globe1';
import Video from './video';
import ImgGalary from './imgGalary';
import Layout from '../../components/Layout';
import About from './about';
import UploadSelfie from './uploadSelfie';
import PopupModal from './PopupModal';
import firebase from '../../firebase';
import animation from './globe.json';
import data from '../../assets/data.json';
import Home from '../Home';
import newflagImage from '../../assets/new_flag.png';
import Footer from '../../components/Navigation/Footer';
import TwitterWall from './twitterWall';
import Certificate from '../Home/Certificate/Certificate';
// import './section.module.scss';
const google = { ...window.google };
const Section = ({ showw, onHide }) => {
  const { t, i18n } = useTranslation();
  const ref = useRef();
  const [globalMap, setGlobalMap] = useState();
  const [clicks, setClicks] = useState([]);
  const [upins, setUpins] = useState([]);
  const [zoom, setZoom] = useState(4);
  const [isMapsLoaded, setIsMapsLoaded] = useState(false);
  const [screenSize, setScreenSize] = useState('');
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const [center, setCenter] = useState({
    lat: 10.8058715,
    lng: 29.1963031,
  });
  const [modalOpen, setModalOpen] = useState(false);

  const [show, setShow] = useState(true);
  const [openC, setOpenC] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const defdatabase = firebase.firestore().collection('pins_staging');

  // useEffect(() => {
  //   setScreenSize(window.innerWidth);

  //   defdatabase.child('pins').once(
  //     'value',
  //     (pins) => {
  //       const pinList = pins.val();

  //       const pinvals = [];

  //       //  console.log(Object.keys(pinList));
  //       Object.keys(pinList).forEach((p) => {
  //         //  console.log(pinList[p]);
  //         pinvals.push(pinList[p]);
  //       });
  //       // for (const p in pinList) {
  //       //   console.log(pinList[p]);

  //       // }

  //       setClicks(pinvals);
  //       //  console.log(pinvals);
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }, [google.maps]);
  //  console.log(screenSize);

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);

  // useEffect(() => {
  //   setScreenSize(window.innerWidth);
  //   if (!isMapsLoaded) {
  //     const intervalId = setInterval(() => {
  //       if (window.google && window.google.maps) {
  //         setIsMapsLoaded(true);
  //       }
  //     });
  //     return () => clearInterval(intervalId);
  //   }
  //   return () => console.log('');
  // }, [google.maps]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const render = (status) => {
    return <h1>{status}</h1>;
  };
  return (
    <>
      <Layout>
        <div
          className="col-12 text-center"
          id="map_view_gradient"
          style={{
            backgroundImage:
              screenSize > 970
                ? `url(${'/images/map.jpg'})`
                : `url(${'/images/o.png'}) `,
            // backgroundImage: `url(${'/images/o.png'}) ,
            //     linear-gradient(111deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%,rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)`,
            top: '40px',
            height: screenSize > 970 && '110vh',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            // position: 'absolute',
            zIndex: 1,
            paddingLeft: screenSize > 970 && '5%',
          }}
        >
          <section className="main_heading">
            {/* <Container fluid>
              <Row
                className="align-items-center pr-5"
                style={{
                  // marginBottom: '20%',
                  marginTop: '4%',
                }}
              >
                <Col
                  lg
                  xs={12}
                  style={{ paddingTop: screenSize < 970 && '14%' }}
                > */}
            <Globe1
              onHide={onHide}
              show={modalOpen}
              setOpenModal={setModalOpen}
              currentLang={currentLang}
            />
            {/* </Col>
                <Col lg xs={12}>
                 
                </Col>
              </Row>
            </Container> */}
          </section>
        </div>

        {/* <div className="banner_cont">
          {screenSize < 970 ? (
            <img
              className="img-fluid"
              src="./images/banner_mob.jpg"
              alt="banner"
            />
          ) : (
            <img className="img-fluid" src="./images/banner.jpg" alt="banner" />
          )}
        </div> */}
        {/* {screenSize < 970 && (
          <>
            <br />
            <br />
            <br />
          </>
        )} */}

        <div
          style={{
            marginTop: screenSize > 970 ? '5%' : '15%',
            backgroundColor: '#fff',
          }}
        >
          <section className="spacing py-3">
            <UploadSelfie
              show={modalOpen}
              setOpenModal={setModalOpen}
              // showPhotoCertificate={showPhotoCertificate}
              // setShowPhotoCertificate={setShowPhotoCertificate}
            />
          </section>
          <section className="spacing  py-5">
            <Video />
          </section>

          <section className="spacing pt-2 mt-2">
            <TwitterWall />
          </section>
          <section className="spacing pt-2 mt-2">
            {/* <Container> */}
            <Row>
              <h3 className="flag_title text-center  d-flex justify-content-center  pt-lg-5 mb-4 text-break">
                Tiranga Filter
              </h3>
            </Row>
            <Row className="m-2">
              {/* {screenSize > 970 ? (
                <img
                  className="img-fluid filter_img"
                  src="./images/group_filter.jpg"
                  alt="share1"
                />
              ) : (
                <> */}{' '}
              <Col
                className="p-2"
                lg={6}
                md={12}
                sm={12}
                // style={{
                //   backgroundImage: `url(${'./images/share1.jpg'})`,
                //   backgroundSize: 'contain',
                //   backgroundPosition: 'center',
                //   backgroundRepeat: 'no-repeat',
                //   height: '46vh',
                // }}
              >
                <img
                  className="img-fluid filter_img"
                  src="./images/share1.jpg"
                  alt="share1"
                />
              </Col>
              <Col
                className="p-2"
                lg={6}
                md={12}
                sm={12}
                // style={{
                //   backgroundImage: `url(${'./images/share2.jpg'})`,
                //   backgroundSize: 'contain',
                //   backgroundPosition: 'center',
                //   backgroundRepeat: 'no-repeat',
                //   height: '46vh',
                // }}
              >
                <img
                  className="img-fluid filter_img"
                  src="./images/share2.jpg"
                  alt="share2"
                />
              </Col>
              {/* </>
              )} */}
            </Row>
            {/* </Container> */}
          </section>

          <section className="spacing pb-5">
            <About screenSize={screenSize} />
          </section>
        </div>
        {/* <Footer /> */}
      </Layout>

      {modalOpen && <PopupModal setOpenModal={setModalOpen} />}
    </>
  );
};

export default Section;

// const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
//   if (google.maps === undefined) return deepEqual(a, b);
//   if (
//     isLatLngLiteral(a) ||
//     (google.maps.LatLng !== undefined && a instanceof google.maps.LatLng) ||
//     isLatLngLiteral(b) ||
//     (google.maps.LatLng !== undefined && b instanceof google.maps.LatLng)
//   ) {
//     return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
//   }
//   // TODO extend to other types
//   // use fast-equals for other objects
//   return deepEqual(a, b);
// });

// function useDeepCompareMemoize(value) {
//   const ref = useRef();

//   if (!deepCompareEqualsForMaps(value, ref.current)) {
//     ref.current = value;
//   }
//   return ref.current;
// }

// function useDeepCompareEffectForMaps(callback, dependencies) {
//   useEffect(callback, dependencies.map(useDeepCompareMemoize));
// }

// const Map = ({
//   onClick,
//   onIdle,
//   children,
//   setGlobalMapp,
//   style,
//   ...options
// }) => {
//   // [START maps_react_map_component_add_map_hooks]
//   const ref = useRef(null);
//   const [map, setMap] = useState();

//   useEffect(() => {
//     if (ref.current && !map) {
//       setMap(new window.google.maps.Map(ref.current, {}));
//       // const input = document.getElementById("map_input");
//       // const searchBox = new google.maps.places.SearchBox(input);
//       setGlobalMapp(map);
//     }
//   }, [ref, map]);
//   // [END maps_react_map_component_add_map_hooks]
//   // [START maps_react_map_component_options_hook]
//   // because React does not do deep comparisons, a custom hook is used
//   // see discussion in https://github.com/googlemaps/js-samples/issues/946
//   useDeepCompareEffectForMaps(() => {
//     if (map) {
//       map.setOptions(options);
//     }
//   }, [map, options]);
//   // [END maps_react_map_component_options_hook]
//   // [START maps_react_map_component_event_hooks]
//   useEffect(() => {
//     if (map && google.maps) {
//       ['click', 'idle'].forEach((eventName) =>
//         google.maps.event.clearListeners(map, eventName)
//       );
//       if (onClick) {
//         map.addListener('click', onClick);
//       }

//       if (onIdle) {
//         map.addListener('idle', () => onIdle(map));
//       }
//     }
//   }, [map, onClick, onIdle]);
//   // [END maps_react_map_component_event_hooks]
//   // [START maps_react_map_component_return]
//   return createElement(
//     Fragment,
//     null,
//     createElement('div', { ref, style }),
//     Children.map(children, (child) => {
//       if (isValidElement(child)) {
//         // set the map prop on the child component
//         return cloneElement(child, { map });
//       }
//       return 0;
//     })
//   );
//   // [END maps_react_map_component_return]
// };

// export { Map };

// const Marker = (options) => {
//   const [marker, setMarker] = useState();

//   useEffect(() => {
//     if (!marker && window.google.maps) {
//       setMarker(new window.google.maps.Marker());
//     }

//     // remove marker from map on unmount
//     return () => {
//       if (marker) {
//         marker.setMap(null);
//       }
//     };
//   }, [marker]);
//   useEffect(() => {
//     if (marker) {
//       marker.setOptions(options);
//     }
//   }, [marker, options]);

//   return null;
// };
// export { Marker };
