import { React, useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import firebase from '../../firebase';

import Layout from '../../components/Layout';

function Selfi() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [images, setImages] = useState([]);
  const [screenSize, setScreenSize] = useState('');
  const [capId, setCapId] = useState();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setScreenSize(window.innerWidth);
    const ref = firebase.firestore();

    // Create a query against the collection

    const db = ref.collection('tiranga');

    db.where('moderated', '==', true)

      .orderBy('timestamp', 'desc')
      .where('moderated', '==', true)
      .limit(100)
      .get()
      .then((snapshot) => {
        const imageList = [];
        snapshot.forEach((value) => {
          imageList.push(value.data());
          // console.log(value.data());
        });
        setImages(imageList);
      });
  }, []);
  return (
    <>
      <Layout>
        <Container
          className="my-4"
          style={{
            justifyContent: 'center',
          }}
        >
          <Row className="about-section align-items-center">
            <Col lg={12} sm={12}>
              <Container className="mt-4">
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  {(images.length >= 0
                    ? images
                    : t('images', {
                      returnObjects: true,
                    })
                  ).map((item, idx) => (
                    <Col
                      lg={4}
                      className="hashtagImg_sm"
                      style={{ display: 'inline-block' }}
                      key={Math.random()}
                    >
                      <div
                        style={{
                          borderRadius: '15px',
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(idx);
                        }}
                      >
                        <VillageImageDiv
                          style={{
                            backgroundColor: '#fff',
                            borderRadius: '15px',
                            backgroundSize: 'cover',
                            filter: 'blur(40px)',
                            height: '18vh',
                            // border: '1px solid black',
                            overflow: 'hidden',
                            backgroundImage: `url(${item.image_path})`,
                            cursor: 'pointer',
                          }}
                        />
                        <VillageImageDiv
                          className="th-border-top th-border-bottom"
                          style={{
                            borderRadius: '15px',
                            position: 'absolute',
                            top: '0%',
                            right: '0%',
                            // border: '2px solid transparent',
                            bottom: '0%',
                            backgroundSize: 'contain',
                            backgroundImage: `url(${item.image_path})`,
                            cursor: 'pointer',
                          }}
                        />
                        <div
                          className="p-2 name_text"
                          style={{
                            position: 'relative',
                            backgroundColor: '#1ca63b',
                            color: '#fff',
                            fontSize: '2vh',
                          }}
                        >
                          {(item?.user_name).toUpperCase()}
                        </div>
                      </div>
                    </Col>
                  ))}
                  {isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]?.image_path}
                      nextSrc={
                        images[(photoIndex + 1) % images.length]?.image_path
                      }
                      prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                          ?.image_path
                      }
                      imageCaption={(images[
                        photoIndex
                      ]?.user_name).toUpperCase()}
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + images.length - 1) % images.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                      }
                    />
                  )}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}

export default Selfi;

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: '#555';
  background-position: center;
  border-radius: 20px;
`;
