import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/analytics';
import * as geofirestore from 'geofirestore';
// import 'firebase/app-check';

const config = {
  apiKey: "AIzaSyA9tlNDusqTBJEWku95yxelB9XkAGzSikw",
  authDomain: "hht-website-v2.firebaseapp.com",
  projectId: "hht-website-v2",
  storageBucket: "hht-website-v2.appspot.com",
  messagingSenderId: "273797277027",
  appId: "1:273797277027:web:402580bbcf5de429451f56",
  measurementId: "G-H5B69WH41F"
};

// const config = {
//   apiKey: "AIzaSyAxmzkGOh99FDueODxTgNNoQlfmtX46qzA",
//   authDomain: "harhathjhanda.firebaseapp.com",
//   projectId: "harhathjhanda",
//   storageBucket: "harhathjhanda.appspot.com",
//   messagingSenderId: "878567490070",
//   appId: "1:878567490070:web:dfed74aadf80a3f263eb37",
//   measurementId: "G-13ZCMH0CVW",
//   databaseURL: "https://harhathjhanda-default-rtdb.asia-southeast1.firebasedatabase.app",

// };

const app = firebase.initializeApp(config);

firebase.database();
firebase.storage();
const firestore = firebase.firestore();

firebase.analytics.isSupported().then((isSupported) => {
  if (isSupported) {
    firebase.analytics();
  }
});
// const appCheck = firebase.appCheck();
// console.log(appCheck);
// appCheck.activate("6Lcj_xwhAAAAAB5r6JUYMYNOy-LCfxj4O17h3TlD"
//   ,
//   true // Set to true to allow auto-refresh.
// );
// appCheck.getToken().then((token) => {
//   console.log(token);
// });

export default firebase;
