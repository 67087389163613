import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Layout from '../../components/Layout';
import Footer from '../../components/Navigation/Footer';

function TermsCondition() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <Layout>
      <div className="container my-4">
        <div className="row">
          <div className="col text-center">
            <div className="h2 my-5" style={{ marginTop: '60px' }}>
              {t('terms_and_condition_title')}
            </div>
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('terms_and_condition_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('cookies')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('terms_and_condition_cookie_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('license')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('terms_and_condition_license_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('community')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('terms_and_condition_community_text')}
          </div>
        </div>
        <div className="text-center">
          <div className="h4 my-5" style={{ marginTop: '60px' }}>
            {t('reservation')}
          </div>
        </div>
        <div className="text-center">
          <div className="p my-5" style={{ marginTop: '60px' }}>
            {t('terms_and_condition_reservation_text')}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TermsCondition;
