import React from 'react';

import { useTranslation, Trans } from 'react-i18next';
import Lottie from 'react-lottie';
import animation from '../../../assets/confetti.json';
import '../PopupModal/PopupModal.css';

function Thankyou({ setShowThankyou, currentLang }) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer-thank" style={{ width: 'auto' }}>
          <a
            className="close_thankyou d-flex justify-content-end"
            // onClick={() => {
            //   setShowThankyou(false);
            // }}
            href="/"
          >
            <img src="./images/x-circle.svg" alt="X" />
          </a>
          <div
            className={
              currentLang === 'mal'
                ? 'popup-header-certificate thankyou_mal'
                : 'popup-header-certificate thankyou'
            }
          >
            <div className="popupText-thank">{t('thankyou1')}</div>
            <div className="popupText-thank">{t('thankyou2')}</div>
            <div className="popupText-thank">{t('thankyou3')}</div>
          </div>

          <Lottie options={defaultOptions} />
          {/* <div
            className="close_thankyou d-flex justify-content-center"
            onClick={() => {
              setShowThankyou(false);
            }}
          >
            <p>{t('close')}</p>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Thankyou;
