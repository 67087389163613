import React from 'react';
import Layout from '../../components/Layout';
import './index.css';

function index() {
  const [HGTLOGOS, setHGTLOGOS] = React.useState([
    {
      png: '/images/downloads/harghar-tiranga-logo-Eng-Blue.png',
      jpg: '/images/downloads/harghar-tiranga-logo-Eng-Blue.jpg',
      pdf: '/images/downloads/harghar-tiranga-logo-Eng-Blue.pdf',
      zip: '/images/downloads/hgt-hindi-blue-logo.zip',
    },
    {
      png: '/images/downloads/harghar-tiranga-logo-Eng-White.png',
      jpg: '/images/downloads/harghar-tiranga-logo-Eng-White.jpg',
      pdf: '/images/downloads/harghar-tiranga-logo-Eng-White.pdf',
      zip: '/images/downloads/hgt-hindi-white-logo.zip',
    },
    {
      png: '/images/downloads/harghar-tiranga-logo-Hindi-Blue.png',
      jpg: '/images/downloads/harghar-tiranga-logo-Hindi-Blue.jpg',
      pdf: '/images/downloads/harghar-tiranga-logo-Hindi-Blue.pdf',
      zip: '/images/downloads/hgt-hindi-hindi-logo.zip',
    },
    {
      png: '/images/downloads/harghar-tiranga-logo-Hindi-White.png',
      jpg: '/images/downloads/harghar-tiranga-logo-Hindi-White.jpg',
      pdf: '/images/downloads/harghar-tiranga-logo-Hindi-White.pdf',
      zip: '/images/downloads/hgt-hindi-white-logo.zip',
    },
  ]);

  const [tStandee, setTStandee] = React.useState([
    {
      png: '/images/downloads/HGT-Standee-Variation-1.png',
      jpg: '/images/downloads/HGT-Standee-Variation-1.jpg',
      pdf: '/images/downloads/HGT-Standee-Variation-1.pdf',
      zip: '/images/downloads/AKAM-Standee-Final-Open-File.zip',
    },
    {
      png: '/images/downloads/HGT-Standee-Variation-2.png',
      jpg: '/images/downloads/HGT-Standee-Variation-2.jpg',
      pdf: '/images/downloads/HGT-Standee-Variation-2.pdf',
      zip: '/images/downloads/AKAM-Standee-Final-Open-File.zip',
    },
    {
      png: '/images/downloads/HGT-Standee-Variation-3.png',
      jpg: '/images/downloads/HGT-Standee-Variation-3.jpg',
      pdf: '/images/downloads/HGT-Standee-Variation-3.pdf',
      zip: '/images/downloads/AKAM-Standee-Final-Open-File.zip',
    },
  ]);

  const [pmQuote, setPmQuote] = React.useState([
    {
      png: '/images/downloads/HHGT-PM-Quote-1.png',
      jpg: '/images/downloads/HGT-PM-Quotes-1-01.jpeg',
      pdf: '/images/downloads/HHGT-PM-Quote-1.pdf',
      zip: '/images/downloads/htg-pm-quote-01.zip',
    },
    {
      png: '/images/downloads/HHGT_PM-Quotes-2.png',
      jpg: '/images/downloads/HGT-PM-Quotes-2-01.jpeg',
      pdf: '/images/downloads/HHGT_PM-Quotes-2.pdf',
      zip:
        'https://firebasestorage.googleapis.com/v0/b/harhathjhanda.appspot.com/o/downloads%2Fhtg-pm-quote-02.zip?alt=media',
    },
  ]);

  const [officialLogos1, setOfficialLogos1] = React.useState([
    {
      poster: '/images/downloads/logo-poster-1.jpeg',
      jpg: '/images/downloads/Eng-logo1/logo.jpeg',
      png: '/images/downloads/Eng-logo1/logo.png',
      pdf: '/images/downloads/Eng-logo1/logo.pdf',
    },
    {
      poster: '/images/downloads/logo-poster-2.jpeg',
      jpg: '/images/downloads/Eng-logo2/logo.jpeg',
      png: '/images/downloads/Eng-logo2/logo.png',
      pdf: '/images/downloads/Eng-logo2/logo.pdf',
    },
    {
      poster: '/images/downloads/logo-poster-3.jpeg',
      jpg: '/images/downloads/Eng-logo3/logo.jpeg',
      png: '/images/downloads/Eng-logo3/logo.png',
      pdf: '/images/downloads/Eng-logo3/logo.pdf',
    },
  ]);

  const [officialLogos2, setOfficialLogos2] = React.useState([
    {
      poster: '/images/downloads/logo-poster-4.jpeg',
      jpg: '/images/downloads/Hindi-logo1/logo.jpeg',
      png: '/images/downloads/Hindi-logo1/logo.png',
      pdf: '/images/downloads/Hindi-logo1/logo.pdf',
    },
    {
      poster: '/images/downloads/logo-poster-5.jpeg',
      jpg: '/images/downloads/Hindi-logo2/logo.jpeg',
      png: '/images/downloads/Hindi-logo2/logo.png',
      pdf: '/images/downloads/Hindi-logo2/logo.pdf',
    },
    {
      poster: '/images/downloads/logo-poster-6.jpeg',
      jpg: '/images/downloads/Hindi-logo3/logo.jpeg',
      png: '/images/downloads/Hindi-logo3/logo.png',
      pdf: '/images/downloads/Hindi-logo3/logo.pdf',
    },
  ]);

  return (
    <Layout>
      <div className="container py-4" style={{ marginTop: '10px' }}>
        <div className="row">
          <div className="col-md-12 ">{/* <h1>Downloads</h1> */}</div>
        </div>
        <br />
        <div className="row mt-6" style={{ margin: '30px' }}>
          <div className="col-md-12">
            <div className="h2 bolder " style={{ fontWeight: 'bold' }}>
              Har Ghar Tiranga Banner
            </div>
          </div>
        </div>
        <div className="row text-center mb-6">
          <div className="col-lg-12 col-md-12 ">
            <div className="logoItem border rounded p-2">
              <div className="imageHolder">
                <img
                  src="/images/downloads/Har-Ghar-Tiranga_Web-banner.jpg"
                  alt=""
                />
              </div>
              <div className="logoDownList">
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <a
                      href="/images/downloads/Har-Ghar-Tiranga_Web-banner.jpg"
                      download=""
                      title="Download"
                    >
                      Download
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row mt-6" style={{ margin: '30px' }}>
          <div className="col-md-12">
            <div className="h2 bolder" style={{ fontWeight: 'bold' }}>
              Har Ghar Tiranga Logo
            </div>
          </div>
        </div>
        <div className="row">
          {HGTLOGOS.map((logo) => {
            return (
              <div className="col-lg-3 col-md-6 ">
                <div className="logoItem border rounded p-2">
                  <div className="imageHolder">
                    <img src={logo.png} alt="" />
                  </div>
                  <div className="logoDownList text-center">
                    <ul style={{ listStyle: 'none' }}>
                      <li>
                        <a href={logo.png} download title="PNG">
                          PNG
                        </a>
                      </li>
                      <li>
                        <a href={logo.jpg} download title="JPG">
                          JPG
                        </a>
                      </li>
                      <li>
                        <a href={logo.pdf} download title="PDF">
                          PDF
                        </a>
                      </li>
                      <li>
                        <a href={logo.zip} download title="Open File">
                          Open File
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row mt-6" style={{ margin: '30px' }}>
          <div className="col-md-12">
            <div className="h2 bolder " style={{ fontWeight: 'bold' }}>
              Har Ghar Tiranga Standee
            </div>
          </div>
        </div>
        <div className="row text-center">
          {tStandee.map((logo) => {
            return (
              <div className="col-lg-4 col-md-6 ">
                <div className="logoItem border rounded p-2">
                  <div className="imageHolder">
                    <img src={logo.png} alt="" />
                  </div>
                  <div className="logoDownList">
                    <ul style={{ listStyle: 'none' }}>
                      <li>
                        <a href={logo.png} download title="PNG">
                          PNG
                        </a>
                      </li>
                      <li>
                        <a href={logo.jpg} download title="JPG">
                          JPG
                        </a>
                      </li>
                      <li>
                        <a href={logo.pdf} download title="PDF">
                          PDF
                        </a>
                      </li>
                      <li>
                        <a href={logo.zip} download title="Open File">
                          Open File
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row mt-6" style={{ margin: '30px' }}>
          <div className="col-md-12">
            <div className="h2 bolder " style={{ fontWeight: 'bold' }}>
              PM Quote
            </div>
          </div>
        </div>
        <div className="row text-center">
          {pmQuote.map((logo) => {
            return (
              <div className="col-lg-6 col-md-12 ">
                <div className="logoItem border rounded p-2">
                  <div className="imageHolder">
                    <img src={logo.jpg} alt="" />
                  </div>
                  <div className="logoDownList">
                    <ul style={{ listStyle: 'none' }}>
                      <li>
                        <a href={logo.png} download title="PNG">
                          PNG
                        </a>
                      </li>
                      <li>
                        <a href={logo.jpg} download title="JPG">
                          JPG
                        </a>
                      </li>
                      <li>
                        <a href={logo.pdf} download title="PDF">
                          PDF
                        </a>
                      </li>
                      <li>
                        <a href={logo.zip} download title="Open File">
                          Open File
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row mt-6" style={{ margin: '30px' }}>
          <div className="col-md-12">
            <div className="h2 bolder " style={{ fontWeight: 'bold' }}>
              Har Ghar Tiranga Footer
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-lg-12 col-md-12 ">
            <div className="logoItem border rounded p-2">
              <div className="imageHolder">
                <img src="/images/downloads/HGT_Footer.jpg" alt="" />
              </div>
              <div className="logoDownList">
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <a
                      href="/images/downloads/HGT-Footer.png"
                      download
                      title="PNG"
                    >
                      PNG
                    </a>
                  </li>
                  <li>
                    <a
                      href="/images/downloads/HGT_Footer.jpg"
                      download
                      title="JPG"
                    >
                      JPG
                    </a>
                  </li>
                  <li>
                    <a
                      href="/images/downloads/HGT-Footer.pdf"
                      download
                      title="PDF"
                    >
                      PDF
                    </a>
                  </li>
                  <li>
                    <a
                      href="/images/downloads/HGT-Updated-Footer.zip"
                      download
                      title="Open File"
                    >
                      Open File
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-6" style={{ margin: '30px' }}>
          <div className="col-md-12">
            <div className="h2 bolder " style={{ fontWeight: 'bold' }}>
              Har Ghar Tiranga Animated Logo
            </div>
          </div>
        </div>
        <div className="row text-center mb-6">
          <div className="col-lg-12 col-md-12 ">
            <div className="logoItem border rounded p-2">
              <div className="imageHolder">
                <img
                  src="/images/downloads/akam-animated-logo-thumb-01.jpeg"
                  alt=""
                />
              </div>
              <div className="logoDownList">
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <a
                      href="writereaddata/portal/images/HGT-animated-logo.zip"
                      download=""
                      title="Download"
                    >
                      Download
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center mt-6">
          <div className="col-lg-4 col-md-6 mt-6">
            <div className="h2 bolder">AKAM Selfie Booth</div>
            <div className="logoItem border rounded p-2">
              <div className="imageHolder">
                <img
                  src="/images/downloads/AKAM-Selfie-Booth-thumb-01.jpeg"
                  alt=""
                />
              </div>
              <div className="logoDownList">
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <a
                      href="/images/downloads/AKAM-Selfie-Booth.png"
                      download
                      title="PNG"
                    >
                      PNG
                    </a>
                  </li>
                  <li>
                    <a
                      href="/images/downloads/AKAM-Selfie-Booth.jpg"
                      download
                      title="JPG"
                    >
                      JPG
                    </a>
                  </li>
                  <li>
                    <a
                      href="/images/downloads/AKAM-Selfie-Booth.pdf"
                      download
                      title="PDF"
                    >
                      PDF
                    </a>
                  </li>
                  <li>
                    <a
                      href="writereaddata/Portal/images/AKAM-Selfie-Booth-HGT.zip"
                      download=""
                      title="Open File"
                    >
                      Open File
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-6">
            <div className="h2 bolder">HarGhartiranga.com</div>
            <div className="logoItem border rounded p-2">
              <div className="imageHolder">
                <img src="/images/downloads/harghartiranga-com.jpg" alt="" />
              </div>
              <div className="logoDownList">
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <a
                      href="/images/downloads/harghartiranga-com.png"
                      download
                      title="PNG"
                    >
                      PNG
                    </a>
                  </li>
                  <li>
                    <a
                      href="/images/downloads/harghartiranga-com.jpg"
                      download
                      title="JPG"
                    >
                      JPG
                    </a>
                  </li>
                  <li>
                    <a
                      href="/images/downloads/harghartiranga-com.pdf"
                      download
                      title="PDF"
                    >
                      PDF
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 ">
            <div className="h2 bolder">Har Ghar Tiranga</div>
            <div className="logoItem border rounded p-2">
              <div className="imageHolder">
                <img src="/images/downloads/HGT-QR-Code.jpg" alt="" />
              </div>
              <div className="logoDownList">
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <a
                      href="/images/downloads/HGT-QR-Code.png"
                      download
                      title="PNG"
                    >
                      PNG
                    </a>
                  </li>
                  <li>
                    <a
                      href="/images/downloads/HGT-QR-Code.jpg"
                      download
                      title="JPG"
                    >
                      JPG
                    </a>
                  </li>
                  <li>
                    <a
                      href="/images/downloads/HGT-QR-Code.pdf"
                      download=""
                      title="PDF"
                    >
                      PDF
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default index;
