import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
// import Lottie from 'react-lottie';
// import animation from './globe.json';
import firebase from '../../firebase';
// import pin from './pin_btn.svg';

import data from '../../assets/data.json';

function globe1({ onHide, setOpenModal, show, currentLang }) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [screenSize, setScreenSize] = React.useState('');
  const [images, setImages] = React.useState([]);
  const [imgCount, setImgCount] = React.useState();
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animation,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };
  const [clicks, setClicks] = React.useState();

  const defdatabase = firebase.firestore().collection('pin_count');
  React.useEffect(() => {
    setScreenSize(window.innerWidth);
    defdatabase
      .doc('oLLupGdRb08mTmUujzYX')
      .get()
      .then((snapshot) => {
        // const pinvals = [];
        // snapshot.forEach((doc) => {
        //   pinvals.push(doc.data());
        // });
        setClicks(snapshot.data().count);
      });
  }, []);

  React.useEffect(() => {
    const ref = firebase.firestore();
    const dbC = firebase.firestore().collection('selfie_count');
    dbC.get().then((res) =>
      res.forEach((value) => {
        setImgCount(value.data().count);
      })
    );

    // Create a query against the collection
    const db = ref.collection('tiranga');

    db.where('moderated', '==', true)

      .orderBy('timestamp', 'desc')
      .limit(9)
      .get()
      .then((snapshot) => {
        const imageList = [];
        snapshot.forEach((value) => {
          // console.log(value.data());
          imageList.push(value.data());
        });
        setImages(imageList);
      });
  }, [show, imgCount]);

  return (
    <Container fluid>
      <Row
        className="align-items-center pr-5"
        style={{
          // marginBottom: '20%',
          marginTop: '4%',
        }}
      >
        <Col lg xs={12} style={{ paddingTop: screenSize < 970 && '14%' }}>
          <div>
            <h1 className="header topic-title">{t('heading')}</h1>
            <p className="sub_header ">{t('sub_heading')}</p>
            <p className="sub_header ">
              <span className="orange">{t('sub1')} </span>{' '}
              <span className="green">{t('sub2')}</span> 2022
            </p>
            <p className="sub_heading blinking pt-2">
              {t('sub_heading_blink')}
            </p>

            <div className="container-fluid" style={{ padding: '5% 2% 1% 0%' }}>
              {screenSize > 970 ? (
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <img
                      className="img-fluid landing_icon"
                      src={t('step_icon1')}
                      alt="step1"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <img
                      className="img-fluid landing_icon"
                      src={t('step_icon2')}
                      alt="step2"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <img
                      className="img-fluid landing_icon"
                      src={t('step_icon3')}
                      alt="step3"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <img
                      className="img-fluid landing_icon"
                      src={t('step_icon4')}
                      alt="step3"
                    />
                  </div>
                </div>
              ) : (
                <table style={{ alignItems: 'center', display: 'inline' }}>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          className="landing_icon"
                          src={t('step_icon1')}
                          alt="step1"
                        />
                      </td>
                      <td>
                        <img
                          className="landing_icon"
                          src={t('step_icon2')}
                          alt="step2"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          className="landing_icon"
                          src={t('step_icon3')}
                          alt="step2"
                        />
                      </td>
                      <td>
                        <img
                          className="landing_icon"
                          src={t('step_icon4')}
                          alt="step2"
                        />
                      </td>
                    </tr>
                  </tbody>
                  {/* <div className="d-flex justify-content-center">
              <img
                className="landing_icon"
                src="./images/step3.png"
                alt="step3"
              />
            </div> */}
                </table>
              )}
              <div className="row mt-3">
                <div className="col-2" style={{ paddingRight: '0' }}>
                  <img
                    className="img-fluid"
                    src="./images/special_flag.png"
                    alt="Special place"
                  />
                </div>
                <div className="col-10 d-flex align-items-center">
                  <h3 className="user_text_q">{t('note_landing')}</h3>
                </div>
              </div>
            </div>
            <div className="">
              <div className="row mx-2">
                <div className="col-lg-5 col-sm-12">
                  <Link className="about_btn" to="/user-detail">
                    <div
                      className="row mt-5 mb-3  d-flex align-items-center"
                      style={{
                        backgroundImage: `url(${'/images/org_btn.svg'})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        color: '#F97428',
                        // height: currentLang.startsWith('mal') ? '15vh' : '6vh',
                        minHeight: '7vh',
                        height: 'auto',
                        width: 'auto',
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }}
                    >
                      <div className="" onClick={onHide}>
                        <p
                          className={
                            currentLang.startsWith('mal') ||
                            currentLang.startsWith('tam')
                              ? 'btn_text_mal text-break mx-2 white'
                              : 'btn_text white p-2'
                          }
                        >
                          {t('pin_a_flag')}
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Row className="justify-content-md-center pt-3">
                    <Col>
                      <h3 className="total_flag">{clicks}</h3>
                      <p
                        className={
                          currentLang.startsWith('mal')
                            ? 'globe_text text-break pt-2'
                            : 'globe_text text-nowrap pt-2'
                        }
                      >
                        {t('globe_text')}
                      </p>
                    </Col>
                  </Row>
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="row mx-2">
                    <div
                      className="about_btn col-lg-12 col-md-12"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      <div
                        className=" row mt-5 mb-3 d-flex align-items-center"
                        style={{
                          border: '2px solid #F97428',
                          color: '#F97428',
                          height: 'auto',
                          minHeight: '7vh',
                          // height: currentLang.startsWith('mal') ? '15vh' : '6vh',
                          width: 'auto',
                          borderRadius: '10px',
                          cursor: 'pointer',
                          // backgroundImage: `url(${'/images/org_btn.svg'})`,
                          // backgroundSize: 'contain',
                          // backgroundPosition: 'center',
                          // backgroundRepeat: 'no-repeat',
                          // width: 'auto',
                          // borderRadius: '10px',
                          // display: 'inline-block',
                          // padding: '3%',
                        }}
                      >
                        <div className="">
                          <p
                            className={
                              currentLang.startsWith('mal') ||
                              currentLang.startsWith('tam')
                                ? 'btn_text_mal text-break'
                                : 'btn_pin '
                            }
                          >
                            {t('upload_selfie_title').toUpperCase()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row className="justify-content-md-center mt-3">
                    <Col className="selfi_counter">
                      <h3 className="total_selfi">{imgCount}</h3>
                      <p className="globe_text text-nowrap py-2">
                        {t('selfi_so_far')}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg xs={12}>
          {/* div class */}
        </Col>
      </Row>
      {/*   <Row>
        <div className="container">
          <div className="row mx-2">
            <div className="col-lg-5 col-sm-12">
              <Link className="about_btn" to="/user-detail">
                <div
                  className="row mt-5 mb-3  d-flex align-items-center"
                  style={{
                    backgroundImage: `url(${'/images/org_btn.svg'})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    color: '#F97428',
                    // height: currentLang.startsWith('mal') ? '15vh' : '6vh',
                    minHeight: '5vh',
                    height: 'auto',
                    width: 'auto',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                >
                  <div className="p-2" onClick={onHide}>
                    <p
                      className={
                        currentLang.startsWith('mal') ||
                        currentLang.startsWith('tam')
                          ? 'btn_text_mal white p-2'
                          : 'btn_text white p-2'
                      }
                    >
                      {t('pin_a_flag')}
                    </p>
                  </div>
                </div>
              </Link>
              <Row className="justify-content-md-center pt-3">
                <Col>
                  <h3 className="total_flag">{clicks}</h3>
                  <p
                    className={
                      currentLang.startsWith('mal')
                        ? 'globe_text text-break pt-2'
                        : 'globe_text text-nowrap pt-2'
                    }
                  >
                    {t('globe_text')}
                  </p>
                </Col>
              </Row>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row mx-2">
                <div
                  className="about_btn col-lg-12 col-md-12"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <div
                    className=" row mt-5 mb-3 d-flex align-items-center"
                    style={{
                      border: '2px solid #F97428',
                      color: '#F97428',
                      height: 'auto',
                      minHeight: '5vh',
                      // height: currentLang.startsWith('mal') ? '15vh' : '6vh',
                      width: 'auto',
                      borderRadius: '10px',
                      cursor: 'pointer',

                    }}
                  >
                    <div className="p-2">
                      <p
                        className={
                          currentLang.startsWith('mal') ||
                          currentLang.startsWith('tam')
                            ? 'btn_text_mal text-break'
                            : 'btn_pin '
                        }
                      >
                        {t('upload_selfie_title').toUpperCase()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Row className="justify-content-md-center mt-3">
                <Col className="selfi_counter">
                  <h3 className="total_selfi">{imgCount}</h3>
                  <p className="globe_text text-nowrap py-2">
                    {t('selfi_so_far')}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row> */}
    </Container>
  );
}

export default globe1;
