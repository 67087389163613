import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Certificate from '../Certificate/Certificate';
import './PopupModal.css';

function Modal({
  setOpenModal,
  image,
  name,
  setShowFlagCertificate,
  showFlagCertificate,
  setShowThankyou,
  showThankyou,
}) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpenModal(false);
    window.open('https://shop.harghartiranga.com', '_blank');
  };

  const [user, setUser] = React.useState('');

  React.useEffect(() => {
    if (showFlagCertificate) {
      setUser(name);
    }
  }, []);

  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer" style={{ width: 'min-content' }}>
          <div
            className="close_thankyou d-flex justify-content-end"
            onClick={() => {
              setOpenModal(false);
              setShowThankyou(true);
            }}
          >
            <img src="./images/x-circle.svg" alt="X" />
          </div>
          {/* <div
            className="float-right"
            onClick={() => {
              setOpenModal(false);
              setShowThankyou(true);
            }}
            style={{ right: 30, position: 'absolute', cursor: 'pointer' }}
          >
            X
          </div> */}
          <div className="popup-header">
            <div className="imagediv">
              {image ? (
                <img
                  src={image}
                  className="popup-image"
                  alt={`${name}-${name.charAt(0).toUpperCase()}`}
                  style={{ borderRadius: '50%' }}
                />
              ) : (
                <div className="alphabet-background-big d-flex justify-content-center  rounded-circle">
                  <div className="alphabet">{name.charAt(0).toUpperCase()}</div>
                </div>
              )}
            </div>
            {/* <div> <h2 className="popup-text">{name}</h2>
             <div className="popup-subtext">
               <p>State: Haryana</p>
               <p>District: Gurugram</p>
             </div>
             </div> */}
          </div>
          <div>
            <div className="popupText">
              <span className="text-orange text-nowrap">
                {' '}
                {t('congratulations')}
              </span>{' '}
              <div className="text-nowrap">{t('cong_text1')}</div>
              <div className="text-nowrap">{t('cong_text2')}</div>
              <div className="text-nowrap">{t('cong_text3')}</div>
            </div>
            {/* <div className="subText">Hey {name}! Do you want to</div> */}
          </div>
          {/* <div className="buy-flag-div d-flex justify-content-center">
          <button
            className="buy-flag"
            onClick={() => {
              handleClick();
            }}
          >
            Buy flag
          </button>
        </div> */}
          <div
            className="skip d-flex justify-content-center cursor-pointer"
            onClick={() => {
              setOpenModal(false);

              setShowFlagCertificate(true);
            }}
          >
            <p>{t('download_certificate')}</p>
          </div>
        </div>
      </div>
      {showFlagCertificate && (
        <Certificate
          name={name}
          image={image}
          setShowFlagCertificate={setShowFlagCertificate}
        />
      )}
    </>
  );
}

export default Modal;
