import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import paths from 'pages/Router/paths';
import Layout from '../../components/Layout';
import data from '../../assets/data/data.json';
import Footer from '../../components/Navigation/Footer';

function FAQ() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <Layout>
        {/* <img className="img-fluid" src="images/about_intro.jpg" alt="" /> */}
        <Container
          // className="child"
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url(${'./images/bg_img1.png'})`,
            backgroundRepeat: 'no-repeat',
            fontFamily: 'sans-serif',
            overflow: 'hidden',
            // height: '80vh',
            // display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row className="about-section align-items-center">
            <Col
              lg={12}
              sm={12}
              style={{ marginTop: '10%', marginBottom: '5%' }}
            >
              <div className="about">
                <h3 className="galary_header">
                  Frequently Asked Questions (FAQs) about the Indian National
                  Flag
                </h3>

                <br />
                <p className="galary_title p-2">
                  Q1. Is the use, display and hoisting of the National Flag
                  guided by any overarching set of instructions?{' '}
                </p>
                <p className="galary_text p-2">
                  Yes– the ‘Flag Code of India 2002’ and the Prevention of
                  Insults to National Honour Act, 1971.
                </p>
                <p className="galary_title p-2">
                  Q2. What is the Flag Code of India?
                </p>
                <p className="galary_text p-2">
                  The Flag Code of India brings together all laws, conventions,
                  practices, and instructions for the display of the National
                  Flag. It governs the display of the National Flag by Private,
                  Public, and Government Institutions. The Flag Code of India
                  took effect on 26th January 2002.{' '}
                </p>
                <p className="galary_title p-2">
                  Q3. Which material can be used to produce the National Flag?
                </p>
                <p className="galary_text p-2">
                  The Flag Code of India, 2002 was amended vide Order dated 30th
                  December, 2021 and National Flag made of polyester or machine
                  made Flag have been allowed. Now, the National Flag shall be
                  made of handspun and handwoven or machine-made,
                  cotton/polyester/wool/silk/khadi bunting.
                </p>
                <p className="galary_title p-2">
                  Q4. What is the appropriate size and ratio of the National
                  Flag?
                </p>
                <p className="galary_text p-2">
                  As per paragraph 1.3 & 1.4 of the Flag Code of India, the
                  National Flag shall be rectangular in shape. The Flag can be
                  of any size but the ratio of the length to the height (width)
                  of the National Flag shall be 3:2.
                </p>
                <p className="galary_title p-2">
                  Q5. Can I display the National Flag at my home?
                </p>
                <p className="galary_text p-2">
                  As per paragraph 2.2 of the Flag Code of India, a member of
                  the public, a private organization, or an educational
                  institution may hoist/display the National Flag on all days or
                  occasions in accordance with the dignity and honour of the
                  National Flag.
                </p>
                <p className="galary_title p-2">
                  Q6. What is the timing for flying the National Flag in the
                  open/at the home?
                </p>
                <p className="galary_text p-2">
                  The Flag Code of India, 2002 was amended vide Order dated 20th
                  July, 2022 and clause (xi) of paragraph 2.2 of Part-II of the
                  Flag Code of India was replaced by the following clause:- (xi)
                  “where the Flag is displayed in open or displayed on the house
                  of a member of public, it may be flown day and night;”
                </p>
                <p className="galary_title p-2">
                  Q7. What should I keep in mind while displaying the National
                  Flag at my home?
                </p>
                <p className="galary_text p-2">
                  Whenever the National Flag is on display, it should occupy the
                  position of honor and should be distinctly placed. A damaged
                  or disheveled National Flag should not be displayed.
                </p>
                <p className="galary_title p-2">
                  Q8. What should I keep in mind to avoid incorrect display of
                  the National Flag?
                </p>
                <p className="galary_text p-2">
                  <ul>
                    <li>
                      The National Flag shall not be displayed in an inverted
                      manner; i.e.; the saffron band should not be the bottom
                      band.
                    </li>
                    <li>
                      A damaged or disheveled National Flag shall not be
                      displayed.
                    </li>
                    <li>
                      The National Flag shall not be dipped in salute to any
                      person or thing.
                    </li>
                    <li>
                      No other flag or bunting shall be placed higher than or
                      above or side by side with the National Flag; nor shall
                      any object including flowers or garlands, or emblem be
                      placed on or above the flagmast from which the National
                      Flag is flown.
                    </li>
                    <li>
                      The National Flag shall not be used as a festoon, rosette,
                      bunting, or in any other manner for decoration.
                    </li>
                    <li>
                      The National Flag shall not be allowed to touch the ground
                      or the floor or trail in the water.
                    </li>
                    <li>
                      The National Flag shall not be displayed or fastened in
                      any manner as may damage it.
                    </li>
                    <li>
                      The National Flag should not be flown from a single
                      masthead (top part of a flagpole) simultaneously with any
                      other flag or flags.
                    </li>
                    <li>
                      The National Flag shall not be used to cover a
                      speaker&apos;s desk, nor shall it be draped over a
                      speaker&apos;s platform.
                    </li>
                    <li>
                      The National Flag shall not be used as a portion of
                      costume or uniform or accessory of any description which
                      is worn below the waist of any person nor shall it be
                      embroidered or printed on cushions, handkerchiefs,
                      napkins, undergarments or any dress material.
                    </li>
                  </ul>
                </p>
                <p className="galary_title p-2">
                  Q9. Are there any rules for prevention of insult to the Indian
                  National Flag?
                </p>
                <p className="galary_text p-2">
                  Yes. In accordance with explanation 4 to section 2 of “the
                  Prevention of Insults to National Honour Act, 1971’, the
                  following should be observed:
                  <ul>
                    <li>
                      {' '}
                      The National Flag shall not be used as a form of drapery
                      in any form whatsoever, including private funerals{' '}
                    </li>
                    <li>
                      {' '}
                      The National Flag shall not be used as a portion of
                      costume or uniform or accessory of any description which
                      is worn below the waist of any person nor shall it be
                      embroidered or printed on cushions, handkerchiefs,
                      napkins, undergarments or any dress material
                    </li>
                    <li> There shall be no lettering upon the National Flag</li>
                    <li>
                      {' '}
                      The National Flag shall not be used to wrap, receive, or
                      deliver things{' '}
                    </li>
                    <li>
                      {' '}
                      The National Flag shall not be used to cover the sides,
                      back, and top of any vehicle.{' '}
                    </li>
                  </ul>
                </p>
                <p className="galary_title p-2">
                  Q10. What is the correct way to display the National Flag in
                  the open/on public buildings?
                </p>
                <p className="galary_text p-2">
                  {/* As per Section III of Part III of the Flag Code of India, if
                  the National Flag is flown on public buildings, it should be
                  flown on all days from sunrise to sunset, irrespective of the
                  weather conditions. It should be hoisted briskly and lowered
                  slowly. */}
                  <ul>
                    <li>
                      {' '}
                      When the National Flag is displayed flat and horizontal on
                      a wall, the saffron band shall be upper most and when
                      displayed vertically, the saffron band shall be to the
                      right in reference to the National Flag i.e., it should be
                      to the left of a person facing it.
                    </li>
                    <li>
                      {' '}
                      When the National Flag is displayed from a staff
                      projecting horizontally or at an angle from a windowsill,
                      balcony, or front of a building, the saffron band shall be
                      at the farther end of the staff.
                    </li>
                  </ul>
                </p>
                <p className="galary_title p-2">
                  Q11. Should the National Flag be flown at half-mast?
                </p>
                <p className="galary_text p-2">
                  The National Flag shall not be flown at half-mast except on
                  occasions instructed by the Government of India. When flown at
                  half-mast, the National Flag shall be hoisted first to the
                  peak/top of the staff, then lowered to the half-mast position.
                  Before lowering the National Flag for the day, it should be
                  raised again to its peak.
                </p>
                <p className="galary_title p-2">
                  Q12. Can I display the National Flag on my car?
                </p>

                <p className="galary_text p-2">
                  The privilege of hosting the National Flag on motor cars is
                  only limited to the following persons as per paragraph 3.44 of
                  the Flag Code of India, 2002.
                  <ul>
                    <li>• President</li>
                    <li>• Vice-President</li>
                    <li>• Governors and Lieutenant Governors</li>
                    <li>• Heads of Indian Missions/Posts</li>
                    <li>• Prime Minister </li>
                    <li>
                      • Cabinet Ministers, Minister of State, and Deputy
                      Ministers of the Union
                    </li>
                    <li>
                      • Chief Minister and Cabinet Minister of a State or Union
                      Territory
                    </li>
                    <li>
                      • Speaker of the Lok Sabha, Deputy Chairman of Rajya
                      Sabha, Deputy Speaker of Lok Sabha, Chairman of
                      Legislative Councils in States, Speakers of the
                      Legislative Assemblies in States and Union Territories,
                      Deputy Chairman of Legislative Council in States, Deputy
                      Speakers of Legislative Assemblies in States and Union
                      Territories
                    </li>
                    <li>• Chief Justice of India</li>
                    <li>• Judges of Supreme Court</li>
                    <li>• Chief Justice of High Courts</li>
                    <li>• Judges of High Courts</li>
                  </ul>
                </p>
                <p className="galary_title p-2">
                  Q13. How can we display the Indian National Flag along with
                  flags of other nations?
                </p>
                <p className="galary_text p-2">
                  <ul>
                    <li>
                      As per paragraph 3.32 of the Flag Code of India, when the
                      National Flag is displayed in a straight line with flags
                      of other countries, the National Flag shall be on the
                      extreme right. The flags of other nations will follow in
                      an alphabetical order as per the English versions of names
                      of the nations.
                    </li>
                    <li>
                      If the flags are flown in a closed circle formation, the
                      National Flag is flown first and is followed by flags of
                      other national in a clockwise manner.{' '}
                    </li>
                    <li>
                      When the flag is displayed against the wall with another
                      flag from crossed staffs, the National Flag shall be on
                      the right and its staff will be in front of the staff of
                      the other flag
                    </li>
                    <li>
                      When the National Flag is flown with flags of other
                      nations, the flag masts shall be of equal size.
                    </li>
                  </ul>
                </p>
                <p className="galary_title p-2">
                  Q14. How should the National Flag be disposed?
                </p>
                <p className="galary_text p-2">
                  <ul>
                    <li>
                      As per paragraph 2.2 of the Flag Code of India, if the
                      National Flag is damaged, it shall be destroyed as a whole
                      in private, preferably by burning or any other method
                      considering the dignity of the National Flag.{' '}
                    </li>
                    <li>
                      The National Flag, if made of paper, is waved by the
                      general public, these flags should not be discarded on the
                      ground. These should be discarded in private, keeping in
                      mind the dignity of the National Flag.
                    </li>
                  </ul>
                </p>

                <p className="galary_text p-2 text-break">Source:</p>
                <a
                  className="galary_text p-2 text-break"
                  href="https://www.mha.gov.in/sites/default/files/flagcodeofindia_070214.pdf"
                >
                  https://www.mha.gov.in/sites/default/files/flagcodeofindia_070214.pdf
                </a>
                <br />
                <a
                  className="galary_text p-2 text-break"
                  href="https://www.mha.gov.in/sites/default/files/Prevention_Insults_National_Honour_Act1971_1.pdf "
                >
                  https://www.mha.gov.in/sites/default/files/Prevention_Insults_National_Honour_Act1971_1.pdf{' '}
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}

export default FAQ;
